@use '~scss/settings' as *;

.documents-submitted-by-candidate {
  &__list {
    @include flex(column);

    gap: 20px;
    margin: 16px 0;

    .uploaded-requested-document {
      &__info {
        color: $color-text-dark-grey;
      }
    }
  }

  > p {
    @extend %heading4;

    margin-bottom: 12px;
    margin-top: 22px;
  }
}
