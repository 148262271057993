@use '~scss/settings' as *;

.form-field-label {
  @include flex(row, flex-start, center);

  @extend %paragraph-subtitle-small;
  @extend %opensans;

  gap: 8px;

  svg {
    margin-left: 4px;

    fill: $color-secondary-light-grey;
  }
  > span {
    color: $color-text-light-grey;
    margin-left: 4px;
  }
}
