@use '~scss/settings' as *;

.pricing-preview {
  @include flex(column);

  gap: 32px;

  &__pay-later {
    @include flex(row, center, center);

    gap: 14px;

    svg {
      @include size(18px);

      min-width: 18px;
      fill: $color-secondary-light-grey;
    }

    p {
      @extend %input-descriptive;
    }
  }

  .searchality-review-card-item {
    > label {
      @extend %paragraph-subtitle-small;
      @extend %opensans;

      color: $color-primary-dark-blue;
    }
  }

  &__note {
    @include font(8px, 12px, 300);
  }

  &--pay-later {
    .searchality-review-card__content {
      border-color: $color-primary-orange;
    }
  }
}
