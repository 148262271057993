@use '~scss/settings' as *;

.checkout-payment {
  @include flex(row, flex-start, flex-start);

  gap: 32px;

  &__left {
    @include flex(column);

    flex: 1;
    gap: 20px;

    &__billing {
      @include card;
      @include gap(18px, column);

      > p.heading {
        @extend %heading4;
      }

      > p.desc {
        @extend %paragraph-descriptive;
      }
    }
  }
}
