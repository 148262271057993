@use '~scss/settings' as *;

.tpb-contact-information {
  @include flex(column);

  height: 100%;

  .tpb-page-content {
    margin-top: 72px;
  }
  &__row-fields {
    @include flex(row);
    @include gap(16px);
    .s-select-wrapper {
      width: 100%;
    }
  }
}
