@use '~scss/settings' as *;

.publications {
  &-modal {
    .ncoded-modal__content {
      max-width: 500px;

      header {
        display: none;
      }
    }

    &__form {
      @include flex(column);

      align-self: stretch;
      gap: 30px;

      button {
        align-self: flex-start;
      }

      &__title {
        &__main {
          @extend %paragraph-subtitle;
        }

        &__sub {
          @extend %paragraph-descriptive;

          margin-top: 8px;
        }
      }

      &__fields {
        @include flex(column);

        gap: 12px;

        > p {
          @extend %paragraph-subtitle-small;

          text-align: center;
        }

        .searchality-error-message {
          margin: 16px 0;
          max-width: unset;
        }
      }
    }
  }

  button[class*='publications__button'] {
    @extend %modal-card-button;
  }

  &__cards {
    @include flex(column);

    gap: 12px;

    &--wrapper {
      margin-top: 32px;

      > p {
        @extend %paragraph-subtitle-small;

        margin-bottom: 5px;
      }
    }
  }
}
