@use '~scss/settings' as *;

.matching-feature-wizard-form {
  @include flex(column, flex-start);

  background: $color-white;
  border: 1px solid rgba(189, 216, 217, 0.78);
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.04),
    0px 4px 4px rgba(235, 235, 235, 0.08),
    0px 4px 14px rgba(231, 231, 231, 0.05), 0px 4px 5px rgba(0, 0, 0, 0.05);
  border-radius: $border-radius-10;
  padding: 20px;

  &__subtitle {
    @extend %paragraph-descriptive;

    margin-top: 8px;
  }

  img {
    align-self: center;
  }

  &__p-container {
    margin-top: 32px;

    max-width: 90%;

    &__title {
      @extend %paragraph-subtitle;
    }
  }

  &__toggle-container {
    @include flex(row, space-between, flex-start);

    margin-top: 24px;

    &__text-container {
      &__title {
        @extend %paragraph-subtitle-small;
      }
    }
  }
}
