@use '~scss/settings' as *;

.candidate-profile-documents {
  padding: 20px 20px 16px 16px;
  border-radius: $border-radius-20;
  border: 1px solid $color-primary-light-teal;
  background: $color-white;

  &__title {
    @extend %paragraph-subtitle;

    color: $color-primary-dark-blue;
    margin-bottom: 12px;
  }

  &__uploaded {
    @include flex(column, flex-start, stretch);

    margin-top: 28px;
    gap: 20px;
  }

  button.candidate-profile-documents__button {
    width: 80%;
    max-width: 220px;
    margin-top: 28px;
    gap: 10px;

    svg {
      margin: unset;
    }
  }

  &__vq-container {
    margin-top: 32px;

    > p {
      @extend %heading4;

      color: $color-primary-dark-blue;
      margin-bottom: 20px;
    }
    &__wrapper {
      @include flex(row, flex-start, center);

      gap: 15px;
      padding: 12px 10px;
      border: 1px solid $color-primary-light-teal;
      border-radius: $border-radius;
      margin-bottom: 12px;

      &__info {
        flex: 1;

        p:first-of-type {
          @extend %paragraph-subtitle-small;

          margin-bottom: 8px;
        }

        p:last-of-type {
          @extend %input-descriptive;

          color: $color-primary-dark-blue;
        }
      }

      svg:first-of-type {
        path {
          fill: $color-secondary-light-grey;
        }
      }
    }
  }
}
