@use '~scss/settings' as *;

.additional-document-modal {
  .ncoded-modal__content {
    max-width: 510px;

    header {
      display: none;
    }

    main {
      padding: 20px;
    }
  }

  &__form {
    width: 100%;

    &__title {
      @extend %paragraph-subtitle;

      margin-bottom: 8px;
    }

    &__description {
      @extend %paragraph-descriptive;

      margin-bottom: 30px;
    }

    button {
      margin-top: 30px;
    }
  }
}
