@use '~scss/settings' as *;

.job-application-relocation {
  @include flex(column);

  gap: 24px;
  align-self: center;
  width: 100%;
  max-width: 550px;

  &__field {
    @include flex(column);

    gap: 12px;

    > p {
      @extend %input;
    }
  }
}
