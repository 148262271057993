@use '~scss/settings' as *;

.hiring-school-information {
  @include flex(column);
  @include gap(20px, column);

  .multipl-or-single-group {
    @include flex;
    @include gap(24px);
  }

  .disabled {
    opacity: 0.7;
    pointer-events: none;
  }

  .radio-group > :not(:first-child) {
    margin-top: 0;
  }

  p {
    @extend %paragraph-subtitle-small;
    @extend %opensans;

    color: $color-secondary-grey;
    max-width: 70%;
  }
}
