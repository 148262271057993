@use '~scss/settings' as *;

.discount-code {
  width: 100%;
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.04),
    0px 4px 4px rgba(235, 235, 235, 0.08),
    0px 4px 14px rgba(231, 231, 231, 0.05), 0px 4px 5px rgba(0, 0, 0, 0.05);
  border-radius: $border-radius-10;
  border: 1px solid $color-primary-light-teal;
  overflow: hidden;
  background-color: $color-white;
  padding: 18px 22px 18px 14px;

  .nd-collapsible__container {
    > div {
      width: 100%;
    }
  }

  &__trigger {
    @include flex(row, space-between, center);

    p {
      @extend %heading4;
      @extend %opensans;
    }

    svg {
      transition: transform 0.33s;
    }
  }

  &__content {
    @include flex(row);

    gap: 28px;
    margin-top: 27px;

    .s-input-wrapper {
      flex: 1;

      .ncoded-input {
        height: 48px;

        input {
          height: 46px;
        }
      }
    }

    &__code-description {
      @extend %input-descriptive;

      color: $color-primary-teal;
      margin-top: 11px;
    }

    button.ncoded-button {
      white-space: nowrap;
      height: 48px;
    }
  }

  &[open] {
    .discount-code__trigger {
      svg {
        transform: rotate3d(0, 0, 1, 180deg);
      }
    }
  }
}
