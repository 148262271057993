@use '~scss/settings' as *;

.searchality-confirm-modal {
  button.delete {
    background-color: $color-error;
  }

  main {
    overflow: hidden !important;

    > p {
      @extend %paragraph;
    }
  }
}
