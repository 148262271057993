@use '~scss/settings' as *;

.header-navigation {
  @include flex;

  gap: 16px;

  .logout-link {
    @include flex(row, center, center);
    @extend %paragraph;

    gap: 8px;
    text-decoration: none;
    color: $color-secondary-light-grey;
  }

  &__nav-link {
    @include flex(row, flex-start, center);

    @extend %heading4;
    @extend %opensans;

    gap: 8px;
    color: $color-text-light-grey;

    svg {
      @include size(20px);
    }

    .photo-preview {
      p {
        @extend %heading4;
      }
    }

    &.active {
      color: $color-primary-dark-blue;
    }

    .red-dot {
      position: relative;

      &::after {
        content: '•';
        position: absolute;
        top: -8px;
        right: -8px;
        color: $color-error;
      }
    }
  }
}
