@use '~scss/settings' as *;

.pricing {
  .card {
    cursor: default;
  }

  ul {
    padding: unset;
    margin: unset;
    list-style-type: none;
  }
}
