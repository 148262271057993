@use '~scss/settings' as *;

.payment-method-item {
  @include flex(row, flex-start, center);

  padding: 12px 18px;

  small {
    @extend %paragraph-descriptive;

    margin-left: auto;
    color: $color-text-dark-grey;
  }

  .svg-button-wrapper {
    margin-left: auto;

    svg {
      fill: $color-error;
    }
  }

  svg {
    @include size(17px);
  }

  .checkbox {
    @include size(17px);
    @include flex(column, center, center);

    color: $color-grey-b7;
    border-radius: 50%;
    border: 1px solid currentColor;

    > div {
      @include size(5px);

      background-color: currentColor;
      display: none;
    }

    &--filled {
      color: $color-primary-teal;
      background-color: $color-grey-e6;

      > div {
        display: unset;
        background-color: currentColor;
        border-radius: inherit;
      }
    }
  }

  &__label {
    @include flex(row, center, center);

    gap: 18px;
    margin-left: 42px;

    svg {
      @include size(24px);
    }
  }

  &:hover {
    cursor: pointer;
  }
}

.detach-payment-method {
  .ncoded-modal__content {
    header {
      align-items: flex-start;

      svg {
        margin-top: 5px;
      }
    }
  }
}
