@use '~scss/settings' as *;

@include phablet() {
  .application-metadata-card {
    @include flex(column, flex-start, flex-start);

    &__right {
      @include flex(column, flex-start, flex-start);

      &__status {
        @include flex(row, flex-start, center);

        margin: 16px 0;
        margin-left: unset;
      }
    }
  }
}
