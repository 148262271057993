@use '~scss/settings' as *;

.account-edit-wrapper {
  @include flex(column);

  gap: 30px;

  &__header {
    @include flex(row, space-between, stretch);

    &__text {
      h1 {
        @extend %paragraph-subtitle;
      }

      > p {
        @extend %paragraph-subtitle-small;

        color: $color-secondary-grey;
        margin-top: 8px;
      }
    }

    &__actions {
      @include flex(row);

      gap: 8px;

      &__save {
        svg {
          g {
            path {
              fill: $color-white;
            }
          }
        }
      }
    }
  }

  &__content {
    align-self: center;
    max-width: 500px;
    width: 100%;
  }
}
