@use '~scss/settings' as *;

.subscription-actions {
  > p {
    @extend %paragraph-subtitle;

    margin-bottom: 12px;
  }

  .subscription-link {
    &--cancel {
      border: 2px solid $color-primary-orange;

      > svg {
        path {
          fill: $color-primary-orange;
        }
      }
    }

    &--checkout {
      border: 2px solid $color-primary-dark-blue;

      svg {
        fill: $color-secondary-light-grey;

        > g {
          path {
            fill: $color-secondary-light-grey;
          }
        }
      }

      > svg {
        path {
          fill: $color-primary-dark-blue;
        }
      }
    }
  }
}
