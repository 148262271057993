@use '~scss/settings' as *;

.job-application-boarding {
  max-width: 450px;
  align-self: center;
  width: 100%;

  &__title {
    @extend %heading4;

    margin-bottom: 8px;
  }

  &__desc {
    @extend %paragraph-descriptive;

    margin-bottom: 8px;
  }
}
