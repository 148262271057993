@use '~scss/settings' as *;

.request-questions {
  @include flex(column);

  gap: 12px;
  margin-top: 28px;

  &__toggle-container {
    @include flex(row, space-between, center);

    gap: 46px;

    &__text {
      @include flex(column);

      gap: 8px;

      &__title {
        @extend %heading4;
      }

      &__desc {
        @extend %paragraph-descriptive;
      }
    }
  }

  .transparent {
    background-color: transparent;
  }

  .answered {
    .question-video-tag__info {
      &__value {
        @extend %input-descriptive;
      }

      &__description {
        @extend %paragraph-subtitle-small;
      }
    }
  }
}
