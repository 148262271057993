@use '~scss/settings' as *;

.image-cropper {
  @include flex(row, flex-start, flex-start);

  width: 100%;
  gap: 42px;

  &__wrapper {
    position: relative;
    flex: 1;
    max-width: 420px;
    min-height: 250px;
    border: 1px solid $color-primary-light-teal;
    border-radius: 10px;
    overflow: hidden;
  }

  &__preview {
    @include flex(column, center, center);

    gap: 10px;
    margin: 16px 0;
    min-width: 100px;

    p {
      @extend %paragraph-subtitle-small;
      @extend %opensans;
    }

    &__img {
      @include size(100px);
      @include flex(row, center, center);

      border-radius: 50%;
      border: 1px solid $color-primary-light-teal;

      img {
        @include size(100%);

        border-radius: 50%;
      }

      &--rect {
        @include size(100px, 60px);
        @include flex(row, center, center);

        border: 1px solid $color-primary-light-teal;

        img {
          @include size(100%);
          border-radius: unset;
        }
      }
    }

    &__actions {
      @include flex(column, flex-end, center);

      width: 100%;
      gap: 8px;

      button.ncoded-button {
        width: 100%;
        padding: 4px;
        min-height: max-content;
      }
    }
  }
}
