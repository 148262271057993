@use '~scss/settings' as *;

.yes-no-explain-field {
  @include flex(column);
  @include gap(16px, column);

  > label {
    @extend %paragraph-subtitle-small;
  }

  &__input {
    @include flex(column);
    @include gap(16px, column);

    > p {
      @extend %paragraph-subtitle-small;

      color: $color-secondary-grey;
    }
  }
}
