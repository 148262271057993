@use '~scss/settings' as *;

.dropzone-profile-image {
  &__upload {
    @include flex(column, center, center);

    --dropzone-action-color: #{$color-primary-light-teal};
    --dropzone-background-color: #{$color-grey-fc};
    --dropzone-text-color: #{$color-black};
    border: 2px dashed var(--dropzone-action-color);
    border-radius: $border-radius-20;
    color: var(--dropzone-text-color);
    background-color: var(--dropzone-background-color);
    width: 100%;
    padding: 32px;
    gap: 24px;

    button {
      border-color: var(--dropzone-action-color);
    }

    p {
      @include font(16px, 19px, 700);

      span {
        font-weight: 400;
      }
    }
  }

  &__upload-preview {
    img {
      @include size(160px);

      border-radius: 100%;
      border: 1px solid $color-primary-teal;
    }

    &--rect {
      img {
        @include size(200px, 120px);
        border-radius: unset;
        object-fit: contain;
      }
    }

    &__actions {
      @include flex(row);
      @include gap(16px);

      margin-top: 16px;
    }
  }
}
