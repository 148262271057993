@use '~scss/settings' as *;

.payment-methods {
  @include card;

  padding: 18px 0 12px;
  height: max-content;

  > p {
    @extend %heading4;
    @extend %opensans;

    padding-bottom: 22px;
    padding-left: 18px;
  }

  > div {
    border-top: 1px solid $color-primary-light-teal;
  }
}
