@use '~scss/settings' as *;

.tpb-page-content {
  @include flex(column, center);
  @include gap(20px, column-reverse);
  flex: 1;
  max-width: 500px;
  margin: 32px 0px;
  &--full-width {
    width: 100%;
  }
  &--centered {
    @include flex(column, center);
  }
  .tpb-submit {
    margin-top: 32px;
    align-self: baseline;
  }

  > .field-datepicker {
    flex: unset;
  }
  .field-datepicker {
    align-self: flex-start;
    .dropdown__trigger > button {
      background-color: white;
    }
  }
  .ncoded-checkbox {
    background-color: transparent;
    &__box {
      background-color: $color-white;
    }
  }
  > img {
    width: 200px;
  }
}
