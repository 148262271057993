@use '~scss/settings' as *;

.field-dropzone {
  @include flex(column, flex-start, flex-start);

  flex: 1;

  > div:first-child {
    width: 100%;
  }

  > small {
    @extend %input-descriptive;

    width: 100%;
    color: $color-error;
    margin-left: 8px;
    margin-top: 8px;
  }

  .collapsabile-dropzone-width {
    > div {
      width: 100%;
    }
  }

  .collapsabile-dropzone-width--preview {
    > div {
      width: fit-content;
    }
  }
}
