@use '~scss/settings' as *;

.toggle-field {
  @include flex(column);

  gap: 16px;

  > label {
    @extend %paragraph-subtitle-small;
  }

  &__group {
    @include flex(row);
    gap: 16px;

    > * {
      flex: 1;
    }
    &--reverse {
      @include flex(row-reverse);

      gap: 16px;
    }
  }
}
