@use '~scss/settings' as *;

.change-password {
  &__form {
    @include flex(column);

    gap: 20px;
    margin-top: 28px;

    > button {
      margin-top: 12px;
    }

    .ncoded-button {
      align-self: baseline;
    }
  }
}
