@use '~scss/settings' as *;

.member-card {
  @include flex(column, space-between, flex-start);

  gap: 28px;
  padding: 20px 16px;
  width: 100%;
  border-top: 1px solid $color-primary-light-teal;
  background-color: $color-white;

  &--clickable {
    cursor: pointer;
  }

  span {
    @include flex(column, flex-start, flex-start);

    gap: 4px;
    max-width: 100%;

    .searchality-basic-tag {
      @include font(16px, 26px, 400);

      background: rgba(189, 216, 217, 0.7);
      border-radius: $border-radius-5;
      padding: 8px;
      color: $color-primary-teal;
      width: fit-content;
    }

    p {
      color: $color-primary-teal;
      max-width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}
