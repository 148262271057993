@use '~scss/settings' as *;

.question-video-empty {
  @include flex(column, flex-start, flex-start);

  gap: 16px;
  margin-top: 28px;

  &__title {
    @extend %heading4;
  }

  &__container {
    @include flex(row, flex-start, center);

    gap: 20px;
    align-self: stretch;
    padding: 12px;
    background-color: $color-white;
    border: 1px solid $color-primary-light-teal;
    border-radius: $border-radius-6;

    svg {
      @include size(42px);

      flex-shrink: 0;
      fill: $color-secondary-light-grey;

      path {
        fill: $color-secondary-light-grey;
      }
    }

    > p {
      flex: 1;
    }
  }
}
