@use '~scss/settings' as *;

.members-table {
  background-color: $color-white;
  border: 1px solid $color-primary-light-teal;
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.04),
    0px 4px 4px rgba(235, 235, 235, 0.08),
    0px 4px 14px rgba(231, 231, 231, 0.05), 0px 4px 5px rgba(0, 0, 0, 0.05);
  border-radius: $border-radius-10;
  padding: 20px;

  &__column {
    &__role {
      > * {
        margin-left: auto;
      }

      .searchality-basic-tag {
        @include font(16px, 26px, 400);

        background: rgba(189, 216, 217, 0.7);
        border-radius: $border-radius-5;
        padding: 8px;
        color: $color-primary-teal;
        width: fit-content;
      }
    }

    &__name {
      &--clickable {
        cursor: pointer;
      }
    }

    &__school {
      p {
        color: $color-text-light-grey;

        max-width: 300px;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }

  .ncoded-select:focus-within .ncoded-select__selected {
    box-shadow: none;
  }

  .s-select {
    width: fit-content;
    &-wrapper {
      @include flex(row, flex-end, center);
    }

    .dropdown-trigger {
      width: fit-content;
    }

    .ncoded-select {
      border: none;
      width: unset;

      &__selected {
        background-color: transparent;
        border: unset;

        svg {
          margin-left: 12px !important;
        }
      }

      &__options {
        text-align: start;
      }
    }
  }

  thead {
    tr {
      vertical-align: text-top;
      padding: 0;

      height: 48px;
      th {
        @extend %paragraph-subtitle-small;

        color: $color-secondary-light-grey;
        text-align: start;
        padding: 0;
        &:last-of-type {
          text-align: end;
        }
      }
    }
  }

  tbody {
    tr {
      td {
        padding: 6px 0;
        &:last-of-type {
          text-align: end;
        }
        strong {
          @extend %paragraph;
        }
      }
    }
  }
}

.change-owner-modal {
  main {
    p[class*='change-owner-role-description'] {
      @extend %paragraph;
    }
  }

  footer {
    @include gap(unset);

    flex-direction: row-reverse;
    justify-content: flex-start;
    gap: 24px;
  }
}
