@use '~scss/settings' as *;

.select-school-modal {
  .ncoded-modal {
    &__content {
      min-width: 960px;
    }
  }

  &__radio-group {
    width: 100%;

    > label[class*='radio-button'] {
      @include gap(unset);

      border: unset;
      flex-direction: row-reverse;
      gap: 22px;
      width: 100%;

      > span {
        flex: 1;
        width: 100%;
      }

      > input {
        display: none;
      }
    }
    > label[class*='radio-button--checked'] {
      background-color: unset;
      color: unset;

      > span {
        > div {
          background-color: $color-primary-light-teal;
        }
      }
    }
  }
}
