@use '~scss/settings' as *;

.vacancies-filters {
  @include flex;

  flex-wrap: wrap;
  flex: 1;
  gap: 12px;

  .ncoded-select {
    width: 148px;

    &__content {
      @extend %input-descriptive;

      cursor: pointer;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      width: 100px;
      color: $color-text-dark-grey;

      + svg {
        margin-left: 16px;
      }
    }

    &__placeholder {
      > .ncoded-select__content {
        opacity: unset;
      }
    }
  }

  &__filter-portal {
    .ncoded-select__option--selected::after {
      margin-left: 12px;
    }

    .dropdown__content {
      max-width: 80%;

      .ncoded-select__option {
        word-break: break-word;
      }
    }
  }

  .disabled {
    pointer-events: none;
    cursor: not-allowed;
    opacity: 0.5;
  }

  &__wrapper {
    @include flex(column, center, flex-start);
  }

  &__outside + details {
    display: none;
  }

  .ncoded-select__selected {
    padding: 0 10px;
    min-height: 48px;
  }
}
