@use '~scss/settings' as *;

.file-information-modal {
  .ncoded-modal__content {
    max-width: 450px;

    main {
      @include flex(column);

      gap: 2px;
    }
  }
  &__info {
    @include flex(column);

    padding: 8px 0;
    gap: 6px;

    label {
      @extend %input-descriptive;

      color: $color-grey-b5;
    }

    p {
      @extend %input;
    }
  }
}
