@use '~scss/settings' as *;

.followup-questions-field {
  &__questions {
    @include flex(column);

    gap: 16px;
    margin-top: 20px;
  }

  &__question-wrapper {
    @include flex(row, flex-start, center);

    gap: 22px;
    margin-top: 20px;

    .s-input-wrapper {
      flex: 1;
    }
  }
}
