@use '~scss/settings' as *;

.matching-vacancies-add-banner {
  a {
    @include flex(row, center, center);

    border: 2px solid $color-primary-dark-blue;
    color: $color-primary-dark-blue;
    padding: 10px;
    font-size: 16px;
    min-width: unset;
    width: max-content;
    border-radius: $border-radius;
    font-weight: 400;
    height: 51px;
  }

  .banner {
    &__content {
      margin-top: 20px;

      .ncoded-checkbox {
        &__label {
          @extend %paragraph;
          margin-left: 12px;
        }

        &__box {
          align-self: center;
        }
      }
    }
  }
}
