@use '~scss/settings' as *;

.subscription-link {
  @include flex(row, space-between, center);
  @include card;

  flex: 1;
  padding: 14px 20px 14px 14px;

  &:hover {
    cursor: pointer;
  }

  &--disabled {
    pointer-events: none;
    opacity: 0.4;
  }

  &__left {
    @include flex(row, flex-start, center);

    gap: 20px;
    max-width: 95%;

    &__text {
      max-width: 95%;

      &__description {
        @extend %input-descriptive;

        color: $color-grey-b5;
      }

      &__price {
        margin-top: 6px;

        color: $color-black;
      }
    }
  }

  &__error {
    @extend %paragraph-descriptive;

    font-style: italic;
    color: $color-error;
    margin-top: 4px;
  }
}
