@use '~scss/settings' as *;

@include phablet() {
  .job-application-not-logged-in {
    @include flex(column, center, center);

    h3,
    p {
      text-align: center;
    }
  }
}
