@use '~scss/settings' as *;

.dropzone-content {
  &__replace-files {
    font-weight: 400;

    span:first-of-type {
      text-decoration: underline;
      width: fit-content;
      cursor: pointer;
      color: $color-primary-dark-blue;
    }
  }

  &__file-array {
    @include flex(column, center, center);

    gap: 32px;

    max-height: 800px;
    overflow: auto;
  }

  &__file-container {
    @include flex(column, center, center);

    gap: 16px;

    &__file-name {
      @include flex(row, center, center);

      p {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        max-width: 300px;
        text-decoration: underline;
        cursor: pointer;
      }

      svg:last-of-type {
        color: $color-secondary-light-grey;
        margin-left: 4px;
        cursor: pointer;
      }
    }

    &__iframe-container {
      @include flex(row, center, center);

      position: relative;
      width: 290px;
      height: 290px;
      overflow: hidden;
      border-radius: $border-radius-10;
      border: 1px solid $color-grey-e6;

      &__iframe {
        min-height: 300px;
        overflow: hidden;
      }

      &__overlay {
        position: absolute;
        inset: 0;
        z-index: 1;
        background-color: transparent;
      }
    }
  }
}

@media (max-width: 440px) {
  .dropzone-content {
    &__file-container {
      &__file-name {
        p {
          max-width: 200px;
        }
      }

      &__iframe {
        max-width: 100%;
      }
    }
  }
}
