@use '~scss/settings' as *;

.auth-signup {
  display: grid;
  grid-template-columns: 415px 500px;
  grid-template-rows: auto;
  grid-template-areas:
    'a b'
    'c b';
  column-gap: 135px;

  &__form {
    grid-area: b;
  }

  &__titles {
    @include flex(column, center);

    gap: 24px;

    h1 {
      @extend %heading2;

      &.school {
        color: $color-primary-dark-blue;
      }
    }
  }

  &__description {
    p {
      margin-bottom: 24px;
    }

    ul {
      padding-left: 24px;
      list-style: unset;
      margin-bottom: 32px;

      li {
        @extend %paragraph;
      }
    }

    button.ncoded-button {
      background-color: $color-white;
    }
  }

  .auth-section {
    grid-area: b;
  }

  &--teacher {
    .auth-signup__titles,
    .auth-signup__description {
      a,
      p {
        color: $color-primary-dark-blue;
      }

      a {
        @extend %paragraph;

        text-decoration: underline;
      }
    }

    .auth-signup__titles {
      > img {
        @include size(154px, 46px);
      }
    }
  }
}
