@use '~scss/settings' as *;

@include phablet {
  .job-application-section {
    &__header {
      margin-top: 16px;
    }

    .references,
    .education,
    .experience {
      > button.ncoded-button {
        width: 100%;
      }
    }
  }
}
