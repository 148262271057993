@use '~scss/settings' as *;

@media (max-width: 1000px) {
  .select-vacancies-modal {
    .ncoded-modal {
      &__content {
        min-width: unset;

        label {
          display: none;
        }

        .searchality-vacancy-card:hover {
          .searchality-vacancy-card__right {
            span {
              display: block;
            }
          }
        }
      }
    }
  }
}
