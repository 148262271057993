@use '~scss/settings' as *;

.contract-information-form-fields {
  @include flex(column);

  gap: 24px;

  &__field-container {
    > .form-field-label {
      margin-bottom: 12px;
    }
  }
}
