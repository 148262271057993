@use '~scss/settings' as *;

.checkout {
  flex: 1;
  background-color: $color-white;
  padding: 64px 110px 40px;
  border-radius: $border-radius-10;
  width: 1080px;

  h1 {
    @extend %paragraph-subtitle;
  }

  > p {
    @extend %paragraph-descriptive;

    margin-top: 8px;
  }

  .checkout-payment {
    margin-top: 42px;
  }
}
