@use '~scss/settings' as *;

.share-vacancy-modal {
  .ncoded-modal__content {
    width: 960px;
    height: 600px;

    header {
      padding: 30px 30px 15px 35px;
    }

    main {
      border-top: 1px solid $color-primary-light-teal;
      padding: 12px 22px 28px 35px;

      .share-vacancy-modal {
        &__list-title {
          margin-bottom: 12px;
        }

        &__list {
          padding-left: 24px;
          list-style: circle;

          li {
            margin-bottom: 8px;
          }
        }

        &__description {
          margin-bottom: 16px;
        }

        &__share-title {
          @extend %paragraph-subtitle-small;

          margin-bottom: 16px;
          font-family: 'Open sans';
        }

        &__share-form {
          @include flex(row, space-between, flex-start);

          width: 100%;
          gap: 20px;
          margin-bottom: 18px;

          > button.ncoded-button {
            height: 52px;
            margin-top: 7px;
          }

          .multiple-select-field {
            flex: 1;
            max-width: 760px;

            .ncoded-select__options {
              max-height: 160px;
            }

            .searchality-tag {
              align-self: unset;

              > span {
                > div {
                  > p {
                    display: none;
                  }
                }
              }
            }

            .share-vacancy-modal__dropdown-item {
              padding-right: 0;

              > p {
                flex: unset;
              }
            }

            .ncoded-select__selected {
              border: 1px solid $color-primary-light-teal;
            }
          }
        }

        &__via-link {
          @include flex(column, flex-start, flex-start);

          gap: 6px;
          margin-bottom: 22px;

          button {
            margin-top: 4px;
          }

          &__title {
            @extend %paragraph-subtitle-small;

            font-family: 'Open sans';
          }

          &__description {
            @extend %paragraph-descriptive;
          }
        }

        &__members-table {
          width: 100%;

          &__title {
            @extend %paragraph-subtitle-small;

            font-family: 'Open sans';
          }

          &__description {
            @extend %paragraph-descriptive;

            margin-top: 6px;
          }

          table {
            border: unset;
            box-shadow: unset;
            border-radius: unset;
            width: 100%;
            padding: 0;
            padding-right: 13px;
            margin-top: 18px;

            thead {
              display: none;
            }
          }
        }
      }
    }
  }

  &__portal {
    > div {
      width: 760px;
    }
  }

  &__dropdown-item {
    @include flex(row, flex-start, center);

    width: 100%;

    > div {
      flex: 2;
    }

    > p {
      color: $color-text-light-grey;
      flex: 1;
    }
  }
}
