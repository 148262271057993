@use '~scss/settings' as *;

.uploaded-document {
  @include flex(row, flex-start, center);

  gap: 16px;
  border-radius: 8px;
  border: 1px solid $color-primary-light-teal;
  background: $color-white;
  padding: 12px 10px;
  color: $color-secondary-light-grey;

  &__info {
    flex: 1;

    &__description {
      @extend %input-descriptive;

      color: $color-primary-dark-blue;
      margin-top: 8px;
    }

    &__name {
      @extend %paragraph-subtitle-small;

      color: $color-text-dark-grey;
    }
  }

  &__done {
    g {
      path {
        fill: $color-primary-teal;
      }
    }
  }

  &--deleted {
    background: $color-gray-e8;
    pointer-events: none;

    .uploaded-document {
      &__done {
        g {
          path {
            fill: $color-secondary-light-grey;
          }
        }
      }
    }
  }
}
