@use '~scss/settings' as *;

.searchality-profile-invite {
  @include flex(column, flex-start, flex-start);

  gap: 20px;
  padding: 20px;
  border-radius: 20px;
  background: $color-white;
  border: 1px solid $color-primary-light-teal;

  .title {
    @extend %paragraph-subtitle;

    color: $color-primary-dark-blue;
  }

  .members {
    @include flex(row-reverse);
    @include gap(-8px, row-reverse);

    position: relative;
  }
  > button {
    gap: 12px;
  }
}

.invite-member-modal {
  .members-table {
    width: 100%;
    border: none;
    box-shadow: none;
    padding: 0;
  }

  .member-cards {
    margin-top: 20px;
  }

  .ncoded-modal__content {
    width: 800px;
  }

  .role-step {
    ul {
      list-style-type: disc;
      margin-inline-start: 40px;
    }
  }
}
