@use '~scss/settings' as *;

.banner {
  @include flex(column);
  @include card;

  border-radius: 20px;
  overflow: hidden;

  &__header {
    @include flex;

    gap: 24px;

    &--colapsible {
      cursor: pointer;
    }

    &__icon {
      @include flex(row, center, center);
      @include size(64px);

      position: relative;
      border-radius: 20px;

      &--blue {
        color: $color-primary-light-teal;

        svg {
          fill: $color-primary-dark-blue;

          path {
            fill: $color-primary-dark-blue;
          }
        }
      }

      &--teal {
        color: $color-primary-light-teal;

        svg {
          fill: $color-primary-teal;

          path {
            fill: $color-primary-teal;
          }
        }
      }

      &--orange {
        color: $color-primary-orange;
      }

      &::before {
        @include absolute(0);

        content: '';
        background-color: currentColor;
        opacity: 0.5;
        border-radius: inherit;
      }

      > svg {
        @include size(32px);
        z-index: 2;
      }
    }

    &__title {
      @include flex(column, center);

      flex: 1;
      gap: 12px;

      > * {
        white-space: pre-wrap;
      }

      h3 {
        @extend %paragraph-subtitle;
      }

      p {
        @extend %paragraph;
      }
    }

    &__collapse__trigger--rotated {
      transform: rotate(180deg);
    }

    button.svg-button-wrapper {
      @include size(18px);

      > svg {
        @include size(100%);
      }
    }
  }

  &__content {
    @include flex(column);

    gap: 12px;

    &__collapsed {
      border-top: 1px solid $color-primary-light-teal;
      margin-top: 20px;
      padding-top: 20px;
    }
  }

  &__actions {
    @include flex();

    gap: 20px;
    margin-top: 20px;
  }
}
