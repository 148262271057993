@use '~scss/settings' as *;

.jahcr-no-right-to-work {
  border: 1px solid $color-primary-light-teal;
  border-radius: $border-radius-20;
  padding: 16px;

  &__not-eligible {
    @include size(100%);

    &__title {
      @extend %paragraph-subtitle;

      color: $color-secondary-light-grey;
    }

    &__description {
      margin-top: 16px;
    }
  }

  &__eligible {
    &__requirements {
      @include flex(column);

      gap: 20px;
      padding-bottom: 20px;
      border-bottom: 1px solid $color-primary-light-teal;
      margin-bottom: 20px;

      &__req {
        &__label {
          @extend %input;
        }

        &__value {
          @extend %input;

          font-weight: 700;
          margin-top: 4px;
        }
      }
    }

    .ncoded-checkbox {
      gap: 20px;
      .ncoded-checkbox__box {
        align-self: center;
      }
    }

    .ncoded-checkbox__label {
      @extend %paragraph;

      color: $color-primary-dark-blue;
      max-width: 370px;
    }
  }

  &--wrapper {
    > p {
      @extend %heading4;

      margin-bottom: 40px;
    }
  }
}
