@use '~scss/settings' as *;

.toggle-button {
  width: 60px;
  height: 34px;

  .ncoded-checkbox {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;

    .ncoded-checkbox__box {
      @include size(100%);

      position: absolute !important;
      background-color: #ccc;
      -webkit-transition: 0.4s;
      transition: 0.4s;
      border-radius: 34px;
      border: unset;
      color: unset;
      display: unset;

      &:before {
        position: absolute;
        content: '';
        height: 26px;
        width: 26px;
        left: 4px;
        bottom: 4px;
        background-color: $color-white;
        -webkit-transition: 0.4s;
        transition: 0.4s;
        border-radius: 50%;
      }

      &:after {
        position: absolute;
        width: 6px;
        left: 20%;
        top: 20%;
        height: 0;
        padding-top: 23%;
        color: $color-primary-dark-blue;
        content: '';
        border-bottom: solid 2px #fff;
        border-right: solid 2px #fff;
        transform: rotate(45deg);
        transition: transform 0.4s;
      }
    }

    input:checked + .ncoded-checkbox__box {
      background-color: $color-primary-dark-blue !important;
    }

    input:focus + .ncoded-checkbox__box {
      box-shadow: 0 0 1px $color-primary-dark-blue;
    }

    input:checked + .ncoded-checkbox__box:before {
      -webkit-transform: translateX(26px);
      -ms-transform: translateX(26px);
      transform: translateX(26px);
    }
    input:checked + .ncoded-checkbox__box::after {
      -webkit-transform: translateX(26px);
      -ms-transform: translateX(26px);
      transform: translateX(26px) rotate(45deg);
      width: 6px;
      left: 24%;
      top: 20%;
      height: 0;
      padding-top: 23%;
      color: $color-primary-dark-blue;
      transition: transform 0.4s;
    }
  }
}
