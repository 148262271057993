@use '~scss/settings' as *;

@include phablet {
  .vacancy-matching-toggle-section {
    @include flex(column, flex-start, flex-start);

    gap: 12px;

    &__text-container {
      .form-field-label {
        svg {
          @include size(30px);
        }

        gap: 12px;
      }
    }
  }
}
