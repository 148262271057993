@use '~scss/settings' as *;

.members-page {
  @include page-content-container();

  &__pagination-info {
    margin-top: 24px;
  }

  &__table,
  .empty-state-members {
    margin-top: 44px;
  }

  .empty-state-members {
    @include flex(column, center, center);

    gap: 24px;
    margin-top: 44px;

    p {
      text-align: center;

      &.title {
        @extend %heading4;

        color: $color-secondary-light-grey;
      }

      &.description {
        color: $color-primary-dark-blue;
      }
    }
  }

  button.members-page__invite-members {
    svg {
      @include size(16px);

      margin: 0 8px 0 18px;

      path {
        @include size(14px);

        fill: $color-white;
      }
    }
  }
}
