@use '~scss/settings' as *;

.mobile-header-navigation {
  @include flex(row, center, center);

  &__side-nav {
    position: fixed;
    top: 50%;
    left: 0;
    height: 100vh;
    max-width: 80%;
    transform: translate(-100%, -50%);
    background-color: $color-white;
    transition: transform 0.3s ease-in-out;
    z-index: 100;

    > nav {
      @include flex(column, center, flex-start);

      padding: 22px 48px;
      gap: 32px;

      > a {
        flex-basis: 46px;
      }

      .photo-preview {
        @include size(20px);

        min-width: 20px;
      }
    }
  }

  &__overlay {
    position: fixed;
    inset: 0;
    opacity: 0;
    z-index: 50;
    background-color: $color-black;
    transition: opacity 0.3s ease-in-out;
    pointer-events: none;
  }

  &--open {
    .mobile-header-navigation {
      &__side-nav {
        transform: translate(0, -50%);
      }

      &__overlay {
        opacity: 0.3;
        pointer-events: all;
      }
    }
  }
}
