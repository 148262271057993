@use '~scss/settings' as *;

.teacher-profile-banner {
  @include card;
  position: relative;
  padding: unset;
  overflow: hidden;
  border-top: unset;

  &__content {
    padding: 24px;
    h4 {
      @extend %paragraph-subtitle;
      @include flex(row, space-between, center);
      color: $color-text-dark-grey;
      margin-bottom: 8px;
      p {
        @extend %paragraph-descriptive;
      }
    }
    p {
      color: $color-secondary-light-grey;
      max-width: 600px;
    }
  }

  &__filled-container {
    @include flex(row);
    &--done {
      background-color: $color-primary-teal;
      height: 10px;
    }
    &--mandatory-undone {
      background-color: #ea996d;
      height: 10px;
    }
    background-color: $color-grey-d9;
    width: 100%;
  }
}
