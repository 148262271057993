@use '~scss/settings' as *;

.button-dropdown {
  position: relative;
  @include flex(row, center, center);

  > .ncoded-button {
    width: 100%;
    border-bottom-right-radius: 0px;
    border-top-right-radius: 0px;
  }
  .dropdown {
    position: unset;

    &__trigger {
      border-left: 1px solid $color-white;

      button {
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
      }
    }
    &__content {
      width: 100%;
      background-color: $color-gray-f6;
      border: 1px solid $color-primary-dark-blue;
      left: 0 !important;

      &--backwards {
        transform: unset;
      }

      ul {
        li {
          margin-bottom: 0px;
          padding: 10px;
          @extend %paragraph-subtitle-small;
          &:hover {
            background-color: $color-primary-dark-blue;
            color: $color-white;
          }
        }
      }
    }
  }
}
