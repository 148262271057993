@use '~scss/settings' as *;

.applications-filters {
  @include gap(16px);
  @include flex(row, flex-start);

  flex: 1;
  width: 100%;

  .ncoded-select {
    width: 148px;

    &__content {
      @extend %input-descriptive;

      cursor: pointer;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      width: 100px;
      color: $color-text-dark-grey;

      + svg {
        margin-left: 16px;
      }
    }

    &__placeholder {
      > .ncoded-select__content {
        opacity: unset;
      }
    }
  }

  &__filter-portal {
    .ncoded-select__option--selected::after {
      margin-left: 12px;
    }

    .dropdown__content {
      max-width: 70%;

      .ncoded-select__option {
        word-break: break-word;
      }
    }
  }

  &__wrapper {
    @include flex(column, center, flex-start);
  }

  .ncoded-select__selected {
    padding: 0 10px;
    min-height: 48px;
  }
}
