@use '~scss/settings' as *;

.unfinised-step-info {
  padding: 24px;
  &__content {
    display: grid;
    grid-template-columns: 1fr 100px;
    align-items: center;
    grid-template-areas:
      'a b'
      'c b';
    &__title {
      grid-area: a;
      @extend %paragraph-subtitle-small;
    }
    &__description {
      grid-area: c;
      @extend %paragraph;
      color: $color-secondary-light-grey;
    }
    &__action {
      grid-area: b;
      button {
        width: 100%;
      }
    }
  }
  &__actions {
    margin-top: 32px;
    .slider-buttons {
      @include flex(row, flex-start);
    }
  }
}
