@use '~scss/settings' as *;

.school-information-stand-alone {
  .fields-row {
    @include flex;
    @include gap(15px);

    > * {
      flex: 1;
    }
  }
}

form.school-information-stand-alone {
  > [class*='wrapper'] + [class*='wrapper'] {
    margin-top: 10px;
  }
}
