@use '~scss/settings' as *;

.school-logo {
  &__hint-container {
    &__add {
      @extend %paragraph-subtitle-small;

      color: $color-secondary-light-grey-2;
      margin-bottom: 18px;
    }

    margin-bottom: 24px;
  }
  &__button-container {
    @include flex(row);

    gap: 8px;
    margin-top: 44px;
  }
}

form.school-logo {
  button[type='submit'] {
    margin-top: unset;
  }
}
