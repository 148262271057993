@use '~scss/settings' as *;

.spb-get-started {
  @include flex(column, flex-start, flex-start);

  > button.get-started {
    max-width: 700px;
  }

  flex: 1;

  &__content {
    @include flex(column, center, flex-start);

    margin-top: 140px;
    max-width: 550px;

    h3 {
      @include font(32px, 38px, 800);

      margin-bottom: 12px;
    }

    p {
      @include font(16px, 26px, 400);

      color: $color-secondary-light-grey;
    }
  }

  .ncoded-button {
    margin-top: 56px;
  }

  &__flow {
    @include flex(column);

    margin-top: 32px;

    li {
      > svg {
        @include size(100%);
      }

      p {
        @extend %paragraph-descriptive;

        text-align: center;
      }
    }

    &__stickers {
      display: grid;
      align-items: center;
      justify-content: center;
      grid-template-columns: repeat(4, 82px);
      column-gap: 40px;

      > span {
        @include flex(row, center, center);
        @include size(64px);

        border-radius: 21px;
        padding: 16px;
        margin: 0 auto;
        position: relative;

        > svg {
          @include size(100%);
        }

        &:not(:first-of-type)::before {
          content: '';
          position: absolute;
          height: 2px;
          width: 48px;
          background-color: #cecece;
          left: -52px;
          border-radius: 20px;
        }
      }

      &__rectangles {
        background-color: $color-orange-soft;
      }

      &__benefits {
        background-color: $color-primary-light-teal;
      }

      &__location {
        background-color: $color-gray-ce;

        path {
          fill: $color-secondary-grey;
        }
      }

      &__curriculum {
        background-color: rgba($color: $color-primary-dark-blue, $alpha: 0.21);

        path {
          fill: $color-primary-dark-blue;
        }
      }
    }

    &__names {
      display: grid;
      grid-template-columns: repeat(4, 82px);
      column-gap: 40px;
      justify-content: space-between;

      .target {
        @include flex(column, center, center);

        gap: 4px;

        p {
          color: $color-grey-b7;
        }
      }
    }
  }
}
