@use '~scss/settings' as *;

.vacancy-delete-confirmation-modal {
  main {
    min-height: 400px;
    .ncoded-select {
      margin-top: 32px;
    }
    > p {
      @extend %paragraph-descriptive;
    }
  }
}
