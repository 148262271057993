@use '~scss/settings' as *;

.vacancy-wrapper {
  &__pagination-wrapper {
    @include flex(row, space-between, center);

    margin-bottom: 8px;
  }

  &__banner-wrapper {
    margin-bottom: 48px;
  }
}
