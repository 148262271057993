@use '~scss/settings' as *;

.video-description-field {
  margin-top: 20px;

  &__video-wrapper {
    @include flex(row, flex-start, center);

    gap: 22px;
    margin-top: 20px;

    .s-input-wrapper {
      flex: 1;
    }
  }
}
