@use '~scss/settings' as *;

.target-locations-field {
  .dropdown {
    width: 100%;

    &__trigger {
      width: 100%;
    }

    &__content {
      width: 100%;
    }
  }
  &__trigger {
    @include flex(row, flex-start, center);

    position: relative;
    gap: 8px;
    padding: 16px;
    width: 100%;

    > .ncoded-checkbox {
      position: absolute;
      right: 16px;
      top: 50%;
      transform: translate(0, -50%);
      z-index: 100;
    }
  }

  &__dropdown-wrapper {
    @include flex(column);
    gap: 8px;
    max-height: 350px;
    overflow: auto;
  }

  .nd-collapsible__container {
    > div {
      width: 100%;
      padding-left: 8px;

      > P {
        @extend %paragraph-descriptive;

        width: 100%;
        text-align: center;
        font-style: italic;
      }
    }
  }

  .checkbox-group-field {
    @include flex(column);

    .ncoded-checkbox {
      flex-direction: row-reverse;
      justify-content: space-between;
      padding: 16px;
      width: 100%;
    }

    gap: 8px;
  }

  &__tags {
    @include flex(row, flex-start, flex-start);

    flex-wrap: wrap;
    gap: 10px;
    margin-top: 24px;

    .tag {
      width: fit-content;
    }
  }
}
