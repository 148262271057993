@use '~scss/settings' as *;

.boarding-school-position-requirements {
  margin-top: 57px;

  h3 {
    @extend %paragraph-subtitle;

    margin-bottom: 24px;
  }

  &__radio-group--wrapper {
    .boarding-description {
      @extend %paragraph-descriptive;

      max-width: unset;
      margin: 8px 0 20px;
      color: $color-secondary-light-grey;
    }
  }

  &__additional-fields {
    margin-top: 32px;

    &__role-desc {
      .form-field-label {
        margin: 16px 0;
      }

      .boarding-description {
        @extend %paragraph-descriptive;

        margin: 20px 0;
        color: $color-secondary-light-grey;
      }
    }
  }
}
