@use '~scss/settings' as *;

.create-school {
  &__layout {
    @extend %main;

    min-height: 700px;
    display: flex;

    main {
      @include flex(row, center, center);

      flex: 1;
      padding: 40px;

      form {
        min-width: 400px;
        max-width: 540px;
        width: 100%;
      }
    }

    &--no-sidenav {
      main {
        padding: 60px 60px 32px;
      }

      nav.side-navigation {
        display: none;
      }
    }
  }

  form {
    h1 {
      @extend %paragraph-subtitle;

      white-space: pre-wrap;
    }

    p.description {
      @extend %paragraph-descriptive;

      margin: 8px 0 32px;
      white-space: pre-wrap;
    }

    button[type='submit'] {
      margin-top: 32px;
    }

    .radio-group {
      max-width: 340px;
    }

    button.ncoded-button.go-back-button {
      margin-bottom: 16px;
    }
  }
}
