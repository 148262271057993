@use '~scss/settings' as *;

.references {
  &__cards {
    @include flex(column);

    gap: 24px;
    margin-top: 12px;
  }

  &__paragraph {
    @extend %input-descriptive;

    color: $color-primary-dark-blue;
  }

  button[class*='references__button'] {
    @extend %modal-card-button;
  }
}

.references-modal {
  .ncoded-modal__content {
    width: 100%;
    max-width: 1000px;

    main {
      @include flex(column);

      gap: 24px;
      overflow: auto;
    }

    footer {
      @include flex(row, flex-start);
    }
  }

  &__basic-info {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 24px;
  }

  &__add-dates {
    margin: 24px 0 12px;
  }

  &__get-in-touch {
    @include flex(column);

    &__title {
      @include font-size(16px, 19px);

      margin: 32px 0 8px;
    }

    &__description {
      @extend %paragraph-descriptive;

      margin-bottom: 28px;
    }

    &__input {
      width: calc(50% - 12px);

      .dropdown {
        max-height: 60px;
      }
    }

    &__phone,
    &__email {
      @include flex(row, flex-start, center);

      width: calc(50% - 12px);
      gap: 24px;

      > div {
        flex: 1;
      }

      svg {
        @include size(24px);

        min-width: 24px;

        path {
          stroke: $color-secondary-light-grey;
          fill: $color-secondary-light-grey;
        }
      }
    }

    &__phone {
      margin-top: 24px;
    }
  }
}
