@use '~scss/settings' as *;

@include phablet {
  .integration {
    section {
      .code-wrapper {
        padding-left: 16px;
      }
    }

    .code-wrapper {
      overflow: hidden;

      &__section {
        width: 100%;

        p + div {
          @include flex(row, flex-start, flex-start);

          gap: 12px;

          pre {
            overflow: auto;

            code {
              width: 670px;
              display: block;
            }
          }

          button.ncoded-button {
            position: static;
          }
        }
      }
    }
  }
}
