@use '~scss/settings' as *;

.candidate-req-documents {
  @include flex(column);

  gap: 16px;
  margin-top: 24px;
  padding-right: 12px;

  &__title {
    @extend %heading4;

    color: $color-primary-dark-blue;
  }

  &__pending-requests {
    @include flex(column);

    gap: 28px;

    &__request {
      @include flex(row, space-between, center);
    }
  }

  &__info {
    @include flex(column);

    gap: 8px;
    flex: 1;

    &__title {
      @extend %paragraph-subtitle-small;
    }

    &__not-uploaded {
      @extend %input-descriptive;

      color: $color-primary-dark-blue;
    }

    &__description {
      @extend %paragraph-descriptive;
    }
  }
}
