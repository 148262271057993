@use '~scss/settings' as *;

.auth-section {
  @include flex(column);

  gap: 24px;

  padding: 44px 60px;
  border: 1px solid $color-primary-light-teal;
  border-radius: $border-radius;
  background-color: $color-white;

  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.04),
    0px 4px 4px rgba(235, 235, 235, 0.08),
    0px 4px 14px rgba(231, 231, 231, 0.05), 0px 4px 5px rgba(0, 0, 0, 0.05);

  &__login {
    @include flex(row, flex-start, center);
    @include gap(12px);
  }

  &__form {
    @include flex(column);

    gap: 24px;

    .ncoded-button {
      align-self: baseline;
    }
    &__actions {
      @include flex(column);
    }
  }
}
