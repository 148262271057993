@use '~scss/settings' as *;

.school-card {
  @include flex(row, space-between, center);
  @include card;

  padding: 48px 24px;

  &--clickable:hover {
    background: $color-grey-f7;
    border: 1px solid $color-primary-teal;
    cursor: pointer;
  }

  &__name {
    @extend %paragraph-subtitle;

    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 70%;
  }

  &__date {
    @extend %paragraph;

    color: $color-text-light-grey;
  }
}
