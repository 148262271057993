@use '~scss/settings' as *;

.manage-subscription {
  flex: 1;
  background-color: $color-white;
  padding: 40px 180px 60px;
  border-radius: $border-radius-10;

  h1 {
    @extend %paragraph-subtitle;
  }

  > p {
    @extend %paragraph-descriptive;

    margin-top: 8px;
  }

  h5 {
    @extend %heading4;
  }

  .your-subscription {
    margin-top: 25px;
  }

  .subscription-actions,
  .upgrade-subscription,
  .edit-subscription {
    margin-top: 32px;
  }
}

@include phablet() {
  .manage-subscription {
    padding: 28px;
  }
}

@include laptop() {
  .manage-subscription {
    padding: 48px;
  }
}
