@use '~scss/settings' as *;

@include phablet {
  .image-cropper {
    @include flex(column, center, center);

    &__wrapper {
      width: 100%;
    }
  }
}
