@use '~scss/settings' as *;

.review {
  @include flex(column, flex-start, stretch);
  @include gap(60px, column);

  max-width: unset !important;
  position: relative;
  flex: 1;

  &__top-container {
    @include flex(column);

    position: relative;
    gap: 12px;
    flex: 1;
    text-align: center;

    h1.review__top-container__title {
      @include font(40px, 47px, 900);

      color: $color-primary-dark-blue;
    }

    p {
      color: $color-secondary-light-grey;
    }

    button {
      position: absolute;
      top: 12px;
    }
  }

  .cards-array {
    @include flex(row, space-evenly, stretch);
    @include gap(44px);

    width: 100%;

    > * {
      flex: 1;

      max-width: 270px;
    }

    align-self: center;
  }

  > button {
    align-self: center;
  }

  .text-button.text-button {
    background-color: transparent;
    color: $color-primary-dark-blue;
  }
}
