@use '~scss/settings' as *;

.personal-information-edit-wrapper {
  @include flex(row, space-between);

  button[class*='text-button'] {
    @extend %paragraph;

    background-color: transparent;
    padding: 0;
    color: $color-primary-dark-blue;
    min-height: unset;

    svg {
      @include size(16px);
    }
  }

  &__content {
    flex: 1;
    max-width: 450px;

    h1 {
      @extend %paragraph-subtitle;
    }

    > p {
      @extend %paragraph-subtitle-small;

      color: $color-secondary-grey;
      margin-top: 8px;
    }
  }
}
