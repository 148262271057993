@use '~scss/settings' as *;

.tpb-education-statement {
  @include flex(column, center, flex-start);

  height: 100%;

  .field-dropzone {
    flex: unset;
  }
}
