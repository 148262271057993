@use '~scss/settings' as *;

.candidate-position-info {
  @include flex(column, center, flex-end);
  @include gap(12px, column);

  &__label {
    @extend %paragraph-descriptive;

    color: $color-text-light-grey;
  }

  &__name {
    @extend %heading4;

    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  &__school {
    @extend %paragraph;

    color: $color-text-light-grey;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
  }
}
