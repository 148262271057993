@use '~scss/settings' as *;

.association-contact-details {
  .s-input-wrapper {
    margin-top: 16px;
  }

  > div:last-of-type {
    @include flex(row);

    gap: 12px;
    margin-top: 16px;
  }
}
