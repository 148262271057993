@use '~scss/settings' as *;

.tpb-personal-information {
  flex: 1;
  .react-datepicker-wrapper {
    width: unset;
  }
  .tpb-page-content {
    margin-top: 72px;
  }
}
