@use '~scss/settings' as *;

.searchality-header {
  @media (max-width: 1023px) {
    &__logo {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &__mobile-navigation-wrapper {
      .logout-link {
        @include flex(column, center, center);
      }
    }

    &__desktop-navigation {
      display: none;
    }
  }

  @media (min-width: 1024px) {
    .mobile-header-navigation,
    &__mobile-navigation-wrapper {
      display: none;
    }

    &__desktop-navigation {
      display: unset;
    }
  }
}
