@use '~scss/settings' as *;

.position-requirements-fields {
  > * {
    margin-bottom: 30px;
  }

  p {
    @extend %paragraph-subtitle-small;

    max-width: 80%;
  }

  .form-field-label {
    max-width: 90%;
  }

  p[class*='__sub'] {
    @extend %paragraph-descriptive;

    color: $color-secondary-light-grey;
    margin-top: 8px;
    max-width: unset;
  }

  &__position,
  &__cover-letter,
  &__reference,
  &__resume {
    @include flex(column);
    @include gap(16px, column);
  }

  &__position {
    p[class*='__label'] {
      @extend %paragraph-descriptive;

      color: $color-text-dark-grey;
      max-width: unset;
    }

    p[class*='__description-pdf'] {
      @extend %paragraph-descriptive;
    }
  }

  &__experience {
    @include flex(column);

    > div {
      margin-top: 16px;
    }

    &__curriculum {
      p {
        margin-bottom: 8px;
      }
    }
  }

  &__required-language {
    @include flex(column);
    @include gap(30px, column);

    p {
      margin-bottom: -6px;
    }

    .two-options-select {
      > p:first-of-type {
        color: $color-secondary-light-grey;
      }
    }
  }

  &__work-in-usa {
    @include flex(column);
    @include gap(24px, column);
  }

  &__radio-group {
    .radio-group {
      @include flex(row, space-between, stretch);
      @include gap(0, column);
      @include gap(16px);

      > * {
        flex: 1;
      }
    }
  }
  &__tag {
    color: $color-primary-light-teal;
    justify-content: space-between;

    > span {
      color: $color-primary-teal;
    }
  }
}
