@use '~scss/settings' as *;

@include phablet() {
  .group-settings {
    @include flex(column);

    > * {
      max-width: unset;
      width: unset;
      flex: 1;
    }
  }
}
