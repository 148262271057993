@use '~scss/settings' as *;

@include phablet() {
  .searchality-profile-header {
    padding: 0 12px;
    &__main {
      @include flex(column, flex-start, stretch);

      p {
        text-align: center;
      }

      &__actions {
        @include flex(column, center, stretch);
      }
    }
  }
}
