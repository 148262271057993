@use '~scss/settings' as *;

@media (max-width: 901px) {
  .searchality-applications {
    .searchality-pagination-info {
      display: none;
    }

    ul {
      margin-top: 16px;
    }

    .applications {
      details {
        display: block;
      }
    }
  }
}
