@use '~scss/settings' as *;

.opportunity-metadata {
  @include flex(column, space-between, flex-start);

  gap: 64px;
  width: 100%;
  border-radius: $border-radius-10;
  border: 1px solid rgba(189, 216, 217, 0.78);
  background: $color-white;
  box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.05),
    0px 4px 14px 0px rgba(231, 231, 231, 0.05),
    0px 4px 4px 0px rgba(235, 235, 235, 0.08),
    0px 8px 10px 0px rgba(0, 0, 0, 0.04);
  padding: 32px;

  &__top {
    &__school-information {
      @include flex(row, flex-start, center);

      gap: 8px;
      margin-bottom: 8px;

      > p {
        @extend %paragraph-subtitle-small;

        position: relative;
      }

      &__state {
        @include flex(row, flex-start, center);

        gap: 12px;

        p {
          @extend %paragraph-subtitle-small;

          color: $color-secondary-light-grey;
        }

        p:first-of-type {
          position: relative;

          &::after {
            @include size(4px);

            content: '';
            position: absolute;

            top: 7px;
            right: -8px;
            background-color: $color-secondary-light-grey;
            border-radius: 50%;
          }
        }
      }
    }
  }

  &__bottom {
    @include flex(column);

    gap: 20px;

    span {
      @include flex(row, flex-start, center);

      gap: 4px;

      svg {
        margin-right: 16px;

        g {
          fill: $color-secondary-light-grey;
        }

        path {
          fill: $color-secondary-light-grey;
        }
      }
    }

    label {
      @extend %paragraph;

      color: $color-primary-dark-blue;
    }

    p {
      @include flex(row, flex-start, center);

      > span {
        margin-right: 4px;
      }
    }
  }

  &--blue {
    color: $color-secondary-light-blue;
  }

  &--green {
    color: $color-primary-teal;
  }

  &--red {
    color: $color-error;
  }
}
