@use '~scss/settings' as *;

@include phablet() {
  .members-page {
    button.members-page__invite-members {
      position: fixed;
      bottom: 12px;
      width: 90%;
      left: 5%;
      z-index: 10;

      &.open {
        display: none;
      }
    }
  }
}
