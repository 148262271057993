@use '~scss/settings' as *;

@include phablet {
  .add-vacancy-question-video {
    @include flex(column, flex-start, flex-start);
    position: unset;
    padding: 0 20px;

    button.go-back-button {
      position: unset;
      padding: 10px 0;
      height: 51px;
    }
  }
}
