@use '~scss/settings' as *;

.communication-setting {
  border-radius: $border-radius;
  border: 1px solid $color-primary-light-teal;
  background: $color-white;
  padding: 12px 12px 0 12px;

  /* Button drop shadow */
  box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.05),
    0px 4px 14px 0px rgba(231, 231, 231, 0.05),
    0px 4px 4px 0px rgba(235, 235, 235, 0.08),
    0px 8px 10px 0px rgba(0, 0, 0, 0.04);

  &__settings {
    @include flex(column);
  }

  &__title {
    padding-bottom: 12px;
    position: relative;

    &::after {
      content: '';
      width: 100%;
      border-bottom: 1px solid $color-primary-light-teal;
      bottom: 0;
      left: 0;
      position: absolute;
    }
  }

  .communication-setting__settings {
    .radio-group {
      @include gap(3px, column);
    }

    .radio-button {
      padding-left: 4px;
      padding-right: 0;
    }

    .radio-button {
      border: unset;

      span[class*='title'] {
        color: $color-text-dark-grey;
      }

      &--checked {
        background-color: unset;

        span[class*='title'] {
          color: $color-text-dark-grey;
        }
      }

      input:checked {
        background-color: $color-primary-teal;

        border: solid 4px #e6e6e6;
      }
    }

    &__toggle {
      @include flex(row, space-between, center);

      padding: 16px 0;
    }
  }

  &__hint {
    @extend %paragraph-descriptive;

    color: $color-secondary-grey;
    margin-top: 16px;
  }

  .toggle-button {
    .ncoded-checkbox input:checked + .ncoded-checkbox__box {
      background-color: $color-secondary-light-grey-2 !important;

      &::after {
        color: $color-secondary-light-grey-2;
      }
    }
  }
}
