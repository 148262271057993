@use '~scss/settings' as *;

@include laptop {
  .login {
    max-width: 509px;
    margin: auto;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-template-areas:
      'a'
      'b'
      'c';
    row-gap: 32px;

    &__titles {
      h1 {
        @include font-size(32px, 37.5px);

        padding-right: 30%;

        + p {
          margin-top: 16px;
        }
      }
    }

    .auth-form {
      button.ncoded-button {
        width: 100%;
      }
    }
  }
}

@include phablet-portrait {
  .login {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-template-areas:
      'a'
      'b'
      'c';
    row-gap: 32px;
    justify-items: center;

    &__titles {
      h1 {
        @include font-size(32px, 38px);

        text-align: center;

        + p {
          margin-top: 16px;
        }
      }

      img {
        display: none;
      }
    }

    &__description {
      @include flex(column, center, center);

      max-width: 500px;
      padding-left: unset;

      p,
      a {
        @include font-size(18px, 25px);

        margin-top: 28px;
        color: $color-primary-light-teal;
        text-align: center;
        width: unset;
      }
    }
  }
}
