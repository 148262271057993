@use '~scss/settings' as *;

.teacher-offers-fields {
  > * {
    margin-bottom: 30px;
  }

  > div {
    > label {
      @extend %opensans;
    }

    > div {
      > label {
        @extend %opensans;
      }
    }
  }

  &__boarding {
    @include flex(column);
    gap: 16px;
  }

  &__salary,
  &__contact-tearms {
    @include flex(column);
    @include gap(16px, column);

    p {
      @include flex(row, flex-start, center);

      svg {
        margin-left: 16px;
        cursor: pointer;
        fill: $color-secondary-light-grey-2;
      }
    }
  }

  &__salary {
    @include gap(24px, column);

    &__inputs {
      @include flex;

      > * {
        flex: 1;
      }

      gap: 24px;
      margin-top: 100px;
    }
  }

  &__contact-tearms {
    .descriptive-paragraph {
      color: $color-text-light-grey;

      font-weight: 400;
    }
  }

  p {
    @extend %paragraph-subtitle-small;

    max-width: 80%;
  }
}
