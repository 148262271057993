@use '~scss/settings' as *;

.members-filters {
  @include flex(column);

  &__filter-portal {
    .ncoded-select__option--selected::after {
      margin-left: 12px;
    }
    .dropdown__content {
      max-width: 50%;
    }
  }

  &__select-wrapper {
    @include flex;

    gap: 12px;
    min-width: 50%;

    .ncoded-select {
      width: 208px;

      &__content {
        @extend %input-descriptive;

        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        width: 160px;
        color: $color-text-dark-grey;

        + svg {
          margin-left: 16px;
        }
      }

      &__placeholder {
        > .ncoded-select__content {
          opacity: unset;
        }
      }

      &__selected {
        padding: 0 10px;
        min-height: 48px;
      }
    }

    button[class*='members-filters__reset'] {
      align-self: flex-end;
      align-items: flex-end;
      padding-bottom: 4px;
    }
  }
}
