@use '~scss/settings' as *;

@include phablet {
  .job-application-header {
    padding: 12px;

    &__content {
      &__left {
        @include flex(column, center, center);

        &__title {
          margin-left: unset;
        }

        &__school-container {
          @include flex(column, center, center);

          margin-left: unset;
          max-width: 100%;

          &__name {
            max-width: 100%;
          }
        }
      }

      img {
        display: none;
      }
    }
  }
}
