@use '~scss/settings' as *;

@include phablet() {
  .members-actions {
    @include flex(column, flex-start, stretch);
    @include gap(unset);

    gap: 16px;

    button.ncoded-button {
      width: unset;
    }
  }
}
