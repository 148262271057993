@use '~scss/settings' as *;

@use '~scss/settings' as *;

.login {
  display: grid;
  grid-template-rows: auto;
  grid-template-areas:
    'a b'
    'c b';
  grid-template-columns: 415px 500px;
  column-gap: 135px;

  &__titles {
    @include flex(column, center);

    gap: 24px;

    h1 {
      @extend %heading2;

      color: $color-primary-orange;
    }

    img {
      @include size(154px, 46px);
    }
  }

  &__description {
    p {
      margin-bottom: 24px;
      width: 80%;
      color: $color-primary-dark-blue;
      position: relative;
    }

    a {
      @extend %paragraph;

      text-decoration: underline;
      color: $color-primary-dark-blue;
    }
  }

  .login__form {
    grid-area: b;
  }

  &__forgot-password {
    @extend %paragraph-descriptive;

    align-self: baseline;
    background-color: transparent;
    padding: 0;
    color: $color-anchor;
  }

  button[class*='login__button'] {
    width: unset;
  }

  &__form {
    @include flex(column, flex-start, stretch);

    gap: 16px;
    margin-top: 26px;
  }

  &__form-title {
    @include flex(row, flex-start, center);

    gap: 12px;
  }

  .auth-form {
    padding: 42px 32px;

    gap: 10px;
  }
}
