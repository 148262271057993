@use '~scss/settings' as *;

.vacancy-empty-state {
  @include flex(column, center, center);

  &__title {
    @extend %heading4;
    @extend %opensans;

    color: $color-secondary-light-grey;
  }

  &__subtitle {
    color: $color-primary-dark-blue;
    margin-top: 8px;
    max-width: 350px;
    text-align: center;
  }

  img {
    margin-top: 48px;
    width: 100%;
    max-width: 600px;
  }
}
