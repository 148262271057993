@use '~scss/settings' as *;

.close-modal {
  .ncoded-modal {
    &__content {
      header {
        button.ncoded-modal__close {
          align-self: flex-start;
        }
      }

      main {
        padding: 40px 0 0;
      }

      footer {
        border: none;

        .close-modal__exit-process {
          @extend %paragraph;
        }

        .close-modal__save-draft {
          @extend %paragraph;

          margin-left: 52px;
        }
      }
    }
  }
}
