@use '~scss/settings' as *;

.object-preview-card {
  @include flex(column, center, flex-start);

  padding: 38px 20px;
  background: $color-white;
  border: 1px solid rgba(189, 216, 217, 0.78);
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.04),
    0px 4px 4px rgba(235, 235, 235, 0.08),
    0px 4px 14px rgba(231, 231, 231, 0.05), 0px 4px 5px rgba(0, 0, 0, 0.05);
  border-radius: $border-radius-10;
  position: relative;
  max-width: 100%;

  &__actions {
    @include flex;
    @include gap(10px);

    position: absolute;
    right: 4px;
    top: 0;
    display: none;

    button[class*='object-preview-card__actions__button'] {
      padding: 0;
      background-color: transparent;

      svg {
        fill: $color-secondary-light-grey;
      }
    }
  }

  &__data {
    @include flex(column, flex-start);
    @include gap(12px, column);

    &__container {
      @include flex;
      @include gap(12px);
    }

    label {
      @extend %paragraph-subtitle-small;
      @extend %opensans;
    }

    p {
      @extend %input-descriptive;

      word-break: break-word;
    }
  }

  &:hover {
    background-color: $color-grey-f2;

    .object-preview-card__actions {
      display: block;
    }
  }
}
