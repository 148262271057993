@use '~scss/settings' as *;

.job-application-footer {
  @include flex(column, center, center);
  @include gap(column);
  @include size(100%);

  background-color: $color-white;
  padding: 32px 0;
  &__image {
    width: 150px;
    height: auto;
  }

  &__powered-by {
    @include font-size(16px, 19px);
  }
}
