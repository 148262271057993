@use '~scss/settings' as *;

.upload-requested-document {
  border-radius: $border-radius-6;
  border: 1px solid $color-primary-light-teal;
  background: $color-white;
  padding: 12px;

  &__title {
    @extend %paragraph-subtitle-small;

    &--wrapper {
      @include flex(row, space-between, center);

      margin-bottom: 4px;

      svg {
        g {
          path {
            fill: $color-primary-teal;
          }
        }
      }
    }
  }

  > p {
    @extend %paragraph-descriptive;

    margin-bottom: 16px;
  }

  &__sub {
    @extend %paragraph-descriptive;

    margin-bottom: 12px;
  }

  &__files {
    @include flex(column);

    gap: 12px;
  }
}
