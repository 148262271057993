@use '~scss/settings' as *;

.searchality-error-message {
  @include flex(row, center, center);

  flex: auto;
  padding: 16px;
  margin: 0 auto 16px;
  max-width: 400px;
  border: 1px solid $color-error;
  border-radius: $border-radius-5;

  > p {
    color: $color-error;
  }
}
