@use '~scss/settings' as *;

.personal-information {
  @include flex(column);

  align-self: center;
  min-width: 60%;

  &__settings {
    margin-top: 28px;

    p {
      @extend %paragraph-subtitle-small;

      margin-bottom: 19px;
    }
  }

  &__login-info {
    margin-top: 50px;
  }

  &__school-role {
    margin-top: 24px;
  }

  button[class*='personal-information__delete'] {
    background-color: $color-orange-light;
  }

  &__actions {
    @include flex;

    gap: 24px;
    margin-top: 24px;

    > * {
      flex: 1;
    }
  }
}
