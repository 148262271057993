@use '~scss/settings' as *;

.candidate-profile-documents-empty {
  padding: 20px 20px 16px 16px;
  border-radius: $border-radius-20;
  border: 1px solid $color-primary-light-teal;
  background: $color-white;

  &__title {
    @extend %paragraph-subtitle;

    color: $color-primary-dark-blue;
    margin-bottom: 12px;
  }

  button.candidate-profile-documents-empty__button {
    max-width: 300px;
    margin-top: 8px;
    gap: 10px;

    svg {
      @include size(16px);

      margin: unset;

      path {
        stroke-width: 4px;
        stroke: $color-primary-dark-blue;
      }
    }
  }
}
