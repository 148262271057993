@use '~scss/settings' as *;

.vacancy-candidates {
  .matching-vacancies-add-banner {
    margin-top: 40px;

    svg {
      stroke: $color-primary-teal;
    }
  }
}
