@use '~scss/settings' as *;

@include phablet {
  .education {
    &__cards {
      grid-template-columns: 100%;
    }
  }

  .education-modal {
    &__basic-info {
      &__first-row,
      &__second-row {
        @include flex(column);
      }
    }

    &__date-range {
      @include flex(column);
    }

    &__activities-textarea {
      width: 100%;
    }
  }
}
