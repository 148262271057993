@use '~scss/settings' as *;

.add-bank-account-modal {
  main {
    gap: 24px;

    > div {
      width: 100%;
    }

    .error {
      color: $color-error;
    }
  }
}
