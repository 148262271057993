@use '~scss/settings' as *;

.references-field {
  > p {
    @extend %input-descriptive;

    width: 100%;
    color: $color-error;
    margin-left: 8px;
    margin-top: 4px;
  }
}
