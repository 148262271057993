@use '~scss/settings' as *;

.application-preview-about-position {
  .title-margin-top {
    margin-top: 16px;
  }

  .curriculum-list {
    margin-bottom: 8px;
  }

  &__detailed-container {
    @include flex(column);

    margin: 16px 0;
    gap: 16px;

    a {
      @include flex(row, flex-start, center);

      @extend %input-descriptive;
      background-color: $color-white;
      gap: 10px;
      color: $color-secondary-light-grey;
      width: fit-content;
      padding: 8px;
      border-radius: 73px;
    }
  }
}
