@use '~scss/settings' as *;

.sort-select {
  width: 150px;
  margin-left: auto;

  .ncoded-select {
    &__selected {
      background-color: transparent;
      border: none;
      justify-content: center;
      cursor: pointer;
    }

    &__content {
      @extend %input-descriptive;

      color: $color-primary-dark-blue;

      + * {
        margin-left: 16px;
      }
    }

    &__placeholder {
      > .ncoded-select__content {
        opacity: unset;
      }
    }
  }

  &:focus-within {
    .ncoded-select__selected {
      box-shadow: none;
    }
  }
}
