@use '~scss/settings' as *;

@include phablet {
  .personal-info {
    &__basic {
      grid-template-columns: 1fr;
    }

    &__video {
      width: 100%;
    }
  }
}
