@use '~scss/settings' as *;

@include phablet {
  .references-modal {
    &__basic-info {
      grid-template-columns: 1fr;
    }

    &__add-dates {
      margin: 24px 0 12px;
    }

    &__date-range {
      @include flex(column);
    }

    &__get-in-touch {
      &__email,
      &__phone {
        width: 100%;
      }

      &__input {
        width: 100%;
      }
    }
  }
}
