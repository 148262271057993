@use '~scss/settings' as *;

.root {
  @include flex(column);

  flex: 1;

  > p {
    @extend %input-descriptive;

    margin: 8px 0 0 4px;
    color: $color-error;
  }

  > label {
    @extend %paragraph-subtitle-small;

    margin-bottom: 4px;
  }

  &--disabled {
    pointer-events: none;
    opacity: 0.5;
  }
}

.selects {
  @include flex(column, flex-start, stretch);

  gap: 30px;

  > div {
    flex: 1;
  }
}

.year-input-error {
  @extend %input-descriptive;

  color: $color-error;
  margin: 10px 0 4px 4px;
}
