@use '~scss/settings' as *;

.application-metadata-card {
  @include flex(row, space-between, flex-start);

  gap: 28px;
  padding: 20px;
  background: $color-white;
  border: 1px solid rgba(189, 216, 217, 0.78);
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.04),
    0px 4px 4px rgba(235, 235, 235, 0.08),
    0px 4px 14px rgba(231, 231, 231, 0.05), 0px 4px 5px rgba(0, 0, 0, 0.05);
  border-radius: $border-radius-10;

  &__left {
    @include flex(column, space-between, flex-start);

    gap: 48px;

    &__title {
      @include flex(column);
      gap: 4px;

      &__main {
        @extend %paragraph-subtitle;

        color: $color-text-dark-grey;
        word-break: break-word;
      }

      &__sub {
        @extend %paragraph-subtitle-small;
        @extend %opensans;

        color: $color-secondary-light-grey;
        word-break: break-word;
      }
    }

    &__school-info {
      @include flex(column);

      gap: 12px;

      &__position {
        @include flex(row, flex-start, center);

        gap: 12px;

        &__type {
          color: $color-secondary-light-grey;
        }

        &__division {
          color: $color-secondary-light-grey;
          position: relative;

          &::before {
            @include size(4px);

            border-radius: 50%;
            content: '';
            position: absolute;
            background-color: $color-text-dark-grey;

            top: 50%;
            left: -6px;
            transform: translate(-50%, -50%);
          }
        }

        svg {
          @include size(20px);
        }
      }

      &__refferals {
        @include flex(row, flex-start, center);

        gap: 13px;
        color: $color-secondary-light-grey;

        svg {
          @include size(20px);

          fill: $color-error;
        }
      }
    }

    &__date {
      @include flex;

      gap: 4px;

      label {
        @extend %paragraph;

        color: $color-text-light-grey;
      }
    }
  }

  &__right {
    @include flex(column, space-between, flex-start);

    align-self: stretch;

    &__status {
      @include flex(row, flex-end, center);

      gap: 12px;
      margin-left: auto;

      label {
        @extend %paragraph-descriptive;

        color: $color-text-light-grey;
      }
    }

    &__actions {
      @include flex;

      gap: 12px;

      .text-button {
        @include flex(row, center, center);

        gap: 4px;
        color: $color-primary-dark-blue;

        svg {
          @include size(20px);
        }
      }

      button[class*='finish-application'] {
        height: fit-content;
        align-self: flex-end;
      }
    }
  }
}
