@use '~scss/settings' as *;

.searchality-heading {
  @include flex(row, space-between, flex-start);

  gap: 28px;
  margin-bottom: 48px;

  h1 {
    @extend %heading3;

    word-break: break-word;
  }
}
