@use '~scss/settings' as *;

.payment {
  @include flex(row);

  gap: 32px;

  > * {
    flex: 1;
  }

  &__manage-subscription {
    @include flex(column);

    gap: 20px;
    margin-top: 32px;
  }

  .searchality-review-card__heading {
    h5 {
      @extend %paragraph-subtitle;

      color: $color-secondary-light-grey-2;
    }
  }
}
