@use '~scss/settings' as *;

.searchality-profile-comments {
  padding: 20px 0;
  border-radius: 20px;
  background: $color-white;
  border: 1px solid $color-primary-light-teal;

  hr {
    margin: 0;
    border: 0.5px solid $color-primary-light-teal;
  }

  .title {
    @extend %paragraph-subtitle;

    padding: 0 20px;
    color: $color-primary-dark-blue;
  }

  &__comments {
    @include flex(column);

    gap: 20px;
    padding: 20px;
  }

  &__add-comment {
    padding: 20px;
  }
}
