@use '~scss/settings' as *;

@include phablet() {
  .teacher-profile-preview {
    @include flex(column);

    margin: unset;
    padding: 0;

    &__wrapper {
      main {
        padding: 48px 0;
        article {
          overflow: hidden;
          display: block;
        }
      }
    }

    &__actions {
      @include flex(column, flex-start, center);

      flex: 1;
      padding: 32px;
      margin: 0;

      > div {
        @include flex(column, center, center);
        @include gap(unset);

        gap: 16px;

        align-self: stretch;

        > button[class*='ncoded-button'] {
          width: unset;
          align-self: stretch;
        }
      }
    }
  }
}
