@use '~scss/settings' as *;

.request-candidates-documents {
  width: 50%;
  margin: 0 auto;
  min-width: 700px;

  .form-field-label {
    @extend %heading4;
  }

  &__title {
    @include flex(row, center, center);
    @extend %paragraph-subtitle;

    position: relative;
    text-align: center;
    margin-bottom: 43px;
    height: 51px;
  }

  &__go-back {
    position: absolute;
    left: -190px;
    top: 0;
  }

  &__form-container {
    max-width: 450px;
    margin: 0 auto;

    &__information {
      @include flex(row, center, center);

      gap: 13px;
      color: $color-secondary-light-grey;

      svg {
        flex-shrink: 0;
      }

      > p {
        @extend %paragraph-descriptive;
      }
    }

    &__form {
      margin-top: 22px;

      .form-field-label {
        margin-bottom: 16px;
        margin-top: 20px;
      }

      .form-field-label:first-of-type {
        margin-top: 0;
      }

      > button {
        margin-top: 20px;
      }
    }
  }

  &__other-documents {
    margin-top: 33px;

    .form-field-label {
      margin-bottom: 12px;
    }

    > button {
      svg {
        @include size(16px);

        margin: unset;

        path {
          stroke-width: 4px;
          stroke: $color-primary-dark-blue;
        }
      }
    }

    > p {
      @extend %paragraph-descriptive;

      margin-bottom: 20px;
    }

    &__list {
      @include flex(column);

      gap: 20px;
      margin: 16px 0;
    }
  }
}
