@use '~scss/settings' as *;

.select-searchality-version {
  @include flex(column);

  gap: 10px;
  border-radius: $border-radius-10;
  border: 1px solid $color-primary-light-teal;
  padding: 15px 28px 20px 16px;
  margin-bottom: 12px;
  max-width: 509px;

  background: $color-white;

  /* Button drop shadow */
  box-shadow: 0px 8px 10px 0px rgba(0, 0, 0, 0.04),
    0px 4px 4px 0px rgba(235, 235, 235, 0.08),
    0px 4px 14px 0px rgba(231, 231, 231, 0.05),
    0px 4px 5px 0px rgba(0, 0, 0, 0.05);

  &__actions {
    @include flex(row);

    gap: 20px;

    &__action {
      @include flex(row, center, center);

      @extend %paragraph;

      gap: 15px;
      padding: 12px;
      flex: 1;

      border-radius: 89px;
      border: 1px solid $color-secondary-light-grey-2;

      background: $color-white;
      color: $color-secondary-light-grey;

      &--active {
        color: $color-primary-dark-blue;
        border: 2px solid $color-primary-dark-blue;
      }
    }
  }
}
