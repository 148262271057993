@use '~scss/settings' as *;

@include phablet {
  .experience {
    &__cards {
      grid-template-columns: 1fr;
    }

    &__activities-textarea {
      width: 100%;
    }
  }

  .experience-modal {
    &__basic-info {
      &__first-row {
        @include flex(column, flex-start, stretch);
      }
    }

    &__date-range {
      @include flex(column, flex-start, stretch);
    }

    &__position-division {
      width: 100%;
    }
  }
}
