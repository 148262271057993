@use '~scss/settings' as *;

.target-positions-field {
  @include flex(column);

  gap: 24px;

  &__tags {
    @include flex(row, flex-start, center);

    flex-wrap: wrap;
    gap: 12px;
    max-width: 100%;
  }
}
