@use '~scss/settings' as *;

@include phablet {
  .searchality-vacancy-card {
    @include flex(column, space-between, stretch);

    min-height: unset;

    &__left {
      @include flex(column, flex-start, stretch);
      max-width: unset;
    }

    &__right {
      @include flex(column-reverse, space-between, stretch);

      > div {
        align-self: unset;
        margin-top: 16px;
      }

      &__status-date {
        height: 22px;
        margin-top: 8px;
        align-self: unset;
      }

      .actions-container {
        margin-top: 4px;
      }
    }

    &:hover {
      .searchality-vacancy-card {
        &__right {
          .actions-container {
            align-self: flex-start;
            margin-top: 8px;
          }

          &__status-date {
            display: none;
          }
        }
      }
    }
  }
}
