@use '~scss/settings' as *;

.opportunity-card {
  @include flex(row, space-between, center);
  @include card;

  position: relative;
  width: 100%;
  margin-bottom: 20px;

  button.svg-button-wrapper {
    position: absolute;
    right: 24px;
    bottom: 8px;
    display: none;
    padding: 0;
    color: $color-secondary-light-grey;

    svg {
      margin: 0;
    }
  }

  &__left {
    @include flex(column, space-between, flex-start);

    max-width: 50%;

    gap: 40px;

    &__top {
      @include flex(column);

      gap: 4px;

      &__school-information {
        @include flex(row, flex-start, center);

        gap: 12px;

        &__name {
          @extend %paragraph-subtitle-small;

          color: $color-secondary-light-grey;

          position: relative;

          &::after {
            @include size(4px);
            content: '';
            position: absolute;
            background-color: $color-secondary-light-grey;
            border-radius: 50%;

            top: 7px;
            right: -6px;
          }
        }

        &__state {
          @extend %paragraph-subtitle-small;

          color: $color-primary-teal;
        }
      }

      &__position-title {
        @extend %paragraph-subtitle;
      }

      &__subject-grade {
        @extend %paragraph-descriptive;

        color: $color-secondary-light-grey;
      }
    }

    &__bottom {
      @include flex(column);

      gap: 4px;

      &__start-date {
        @include flex(row, flex-start, center);

        gap: 4px;

        label {
          @extend %paragraph-subtitle-small;

          color: $color-secondary-light-grey;
        }

        p {
          @extend %paragraph-subtitle-small;

          color: $color-primary-teal;
        }
      }

      &__published-at {
        @extend %paragraph-descriptive;

        color: $color-secondary-light-grey;
      }
    }
  }

  &:hover {
    background: $color-grey-f7;
    border: 1px solid $color-primary-teal;

    button.svg-button-wrapper {
      display: flex;
    }
  }
}
