@use '~scss/settings' as *;

.profile-not-ready-banner {
  @include flex(column);

  padding: 20px;
  gap: 24px;
  border: 1px solid $color-error;
  border-radius: 20px;
  background-color: $color-white;
  margin: 0 12px;

  p {
    white-space: pre-wrap;
    color: $color-text-dark-grey;
  }

  &__title {
    @include flex(row, flex-start, center);

    gap: 8px;

    p {
      @extend %heading4;

      color: $color-primary-dark-blue;
    }
  }
}
