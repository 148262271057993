@use '~scss/settings' as *;

.jahcr-have-right-to-work {
  &__title {
    @extend %input;

    margin-bottom: 8px;
  }

  &__description {
    @extend %paragraph-descriptive;
  }

  &__radio-group {
    @include gap(unset);

    gap: 24px;
    flex-wrap: wrap;
    margin-top: 24px;

    > label[class*='radio-button'] {
      @include flex(row, center, center);

      min-width: 85px;
      border-radius: 68px;

      > span {
        @extend %paragraph;
      }

      > input {
        display: none;
      }
    }
  }

  &__passports {
    margin-top: 27px;

    > div {
      margin-top: 16px;
    }

    .react-datepicker__custom-input-wrapper {
      > input::placeholder {
        color: $color-grey-af;
      }
    }
  }
}
