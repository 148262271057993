@use '~scss/settings' as *;

.month-day-field {
  @include flex(column);

  gap: 8px;

  &__fields {
    @include flex(row, flex-start, center);

    gap: 12px;

    > * {
      flex: 1;
    }
  }

  &__error {
    @extend %input-descriptive;

    color: $color-error;
    margin-top: 8px;
    margin-left: 4px;
  }
}
