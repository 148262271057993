@use '~scss/settings' as *;

.tpb-page-heading {
  margin-bottom: 32px;
  h4 {
    @extend %paragraph-subtitle;
    margin-bottom: 8px;
  }
  p {
    @extend %paragraph-descriptive;
    margin-bottom: 24px;
  }
}
