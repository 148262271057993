@use '~scss/settings' as *;

.job-application-main {
  @include size(100%);
  max-width: 900px;
  margin: 0 auto;
  padding: 48px 0;

  &--loading {
    opacity: 0.4;
    pointer-events: none;
  }

  .form-field-label {
    @extend %paragraph-subtitle-small;
    @extend %opensans;
  }
}
