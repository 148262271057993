.school-communication-settings {
  .account-edit-wrapper__content {
    form {
      .communication-setting--wrapper:last-of-type {
        > div {
          padding: 0 12px;
        }
      }
    }
  }
}
