@use '~scss/settings' as *;

.order-item {
  @include flex(row, space-between, center);

  border-bottom: 1px solid $color-primary-light-teal;
  padding-bottom: 20px;
  gap: 12px;

  &__name {
    @extend %paragraph-descriptive;
    @extend %opensans;

    text-overflow: ellipsis;
    overflow: hidden;

    &__price {
      @extend %paragraph-subtitle-small;

      color: $color-primary-teal;

      > span {
        text-decoration: line-through;
      }
    }
  }
}
