@use '~scss/settings' as *;

.searchality-vacancy-card {
  @include flex(row, space-between, stretch);
  @include card;

  min-height: 170px;
  margin-bottom: 20px;

  svg {
    @include size(22px);
  }

  &:hover {
    background: $color-grey-f7;
    border: 1px solid $color-primary-teal;

    .actions-container {
      @include flex(row, flex-end, center);
      @include gap(unset);

      gap: 30px;
      color: $color-secondary-light-grey;
      align-self: flex-end;

      > svg {
        cursor: pointer;
      }
    }

    .searchality-vacancy-card__right__status-date {
      display: none;
    }
  }

  &__left {
    @include flex(column, flex-start, flex-start);

    flex: 1;
    max-width: 60%;

    &__school-type {
      @include flex(row, flex-start, center);

      @extend %paragraph-subtitle-small;
      @extend %opensans;

      gap: 10px;
      color: $color-secondary-light-grey;
    }

    &__school-name {
      max-width: 100%;

      p {
        @include font(16px, 19px);

        color: $color-text-light-grey;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        max-width: 350px;
      }
    }

    &__position {
      @extend %paragraph-subtitle;

      max-width: 100%;
      margin-top: 4px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    &__type {
      @extend %paragraph-descriptive;

      color: $color-text-light-grey;
      margin-top: 8px;
      margin-bottom: 44px;
      min-height: 16px;
    }
  }

  &__right {
    @include flex(column, space-between, flex-start);

    > div {
      @include flex(row, flex-start, center);
      @include font-size(12px, 14px);
      @include gap(16px);

      align-self: flex-end;

      > label {
        font-weight: 300;
        color: $color-text-light-grey;
      }
    }

    &__status-date {
      @include font-size(16px, 26px);

      color: $color-text-light-grey;
      height: 22px;
      align-self: end;
    }

    .actions-container {
      display: none;
    }
  }
}

.tooltip-information.actions-container__tooltip {
  padding: 16px;
}
