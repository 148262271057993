@use '~scss/settings' as *;

.position-information-fields {
  > * {
    margin-bottom: 30px;
  }

  p {
    @extend %paragraph-subtitle-small;
  }

  &__position-variables {
    @include flex(column);
    @include gap(20px, column);

    p {
      @include flex;

      svg {
        margin-left: 16px;
        cursor: pointer;
      }
    }
  }

  &__role-description {
    @include flex(column);

    gap: 16px;
    margin-top: 32px;

    p[class*='__description-pdf'] {
      @extend %paragraph-descriptive;

      color: $color-secondary-light-grey;
    }
  }

  &__start-date {
    @include flex(column);
    @include gap(8px, column);

    > p:last-of-type {
      @extend %paragraph-descriptive;

      color: $color-secondary-light-grey;
      max-width: unset;
    }

    &__inputs {
      @include flex(row, space-between, stretch);
      @include gap(16px);

      > * {
        flex: 1;
      }
    }

    &__date-picker {
      filter: drop-shadow(0px 8px 10px rgba(0, 0, 0, 0.04))
        drop-shadow(0px 4px 4px rgba(235, 235, 235, 0.08))
        drop-shadow(0px 4px 14px rgba(231, 231, 231, 0.05))
        drop-shadow(0px 4px 5px rgba(0, 0, 0, 0.05));

      .dropdown {
        border-radius: 8px;
        background-color: $color-white;

        &__trigger {
          button {
            min-height: 56px;
          }
        }
      }
    }
  }

  &__employment-type,
  &__contract-type {
    @include flex(column);
    @include gap(16px, column);

    .optional-text {
      color: $color-secondary-light-grey;
      margin-bottom: -8px;
    }
  }

  &__radio-group {
    .radio-group {
      @include flex(row, space-between, stretch);
      @include gap(0, column);
      @include gap(16px);

      > * {
        flex: 1;
      }
    }
  }
}
