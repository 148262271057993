@use '~scss/settings' as *;

.card {
  @include flex(column);

  padding: 16px 24px;
  border: 1px solid $color-primary-light-teal;
  border-radius: 10px;
  cursor: pointer;
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.04),
    0px 4px 4px rgba(235, 235, 235, 0.08),
    0px 4px 14px rgba(231, 231, 231, 0.05), 0px 4px 5px rgba(0, 0, 0, 0.05);

  &.teal {
    border-color: $color-primary-teal;
  }

  &.orange {
    border-color: $color-orange-light;
  }

  &.teal,
  &.orange {
    border-top-width: 12px;
  }

  p {
    &.card {
      &__title {
        @extend %heading4;

        margin-bottom: 22px;
      }

      &__subtitle {
        margin-bottom: 8px;
      }

      &__desc {
        @extend %paragraph-descriptive;

        color: $color-secondary-light-grey;
        margin-bottom: 14px;
      }

      &__hint {
        @extend %input-descriptive;
        @include flex(row, flex-start, center);

        gap: 6px;
        color: $color-secondary-light-grey;
      }
    }
  }
}
