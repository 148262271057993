@use '~scss/settings' as *;

@include phablet() {
  .teacher-profile-builder-page-wrapper {
    flex-direction: column;
    padding: 16px;

    button[class*='tpb-submit'] {
      flex: 1;
    }
  }
}
