@use '~scss/settings' as *;

.portrait-photo-modal {
  &__description {
    @include flex(row, flex-start, center);

    gap: 8px;
    margin-top: 18px;
  }
}
