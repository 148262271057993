@use '~scss/settings' as *;

.radio-group-wrapper {
  @include flex(column);

  .disabled {
    pointer-events: none;
    opacity: 0.5;
  }
}
