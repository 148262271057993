@use '~scss/settings' as *;

.my-account-links-container {
  @include flex(column);
  border-radius: $border-radius;
  border: 1px solid $color-primary-light-teal;
  background: $color-white;
  padding-right: 12px;

  box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.05),
    0px 4px 14px 0px rgba(231, 231, 231, 0.05),
    0px 4px 4px 0px rgba(235, 235, 235, 0.08),
    0px 8px 10px 0px rgba(0, 0, 0, 0.04);

  a {
    @include flex(row, space-between, center);

    @extend %paragraph;

    color: $color-text-dark-grey;
    padding: 16px 0 16px 16px;

    svg {
      transform: rotate(-90deg);
    }
  }
}
