@use '~scss/settings' as *;

.matching-advertisement-wizard-form {
  @include flex(column, flex-start);

  background: $color-white;
  border: 1px solid rgba(189, 216, 217, 0.78);
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.04),
    0px 4px 4px rgba(235, 235, 235, 0.08),
    0px 4px 14px rgba(231, 231, 231, 0.05), 0px 4px 5px rgba(0, 0, 0, 0.05);
  border-radius: $border-radius-10;
  padding: 20px;

  img {
    align-self: center;
  }

  &__p-container {
    margin-top: 32px;

    &__title {
      @extend %paragraph-subtitle;
    }

    &__subtitle {
      @extend %paragraph-descriptive;

      margin-top: 8px;
    }
  }

  &__actions-container {
    @include flex(row, space-between, center);

    margin-top: 20px;

    a {
      @include flex(row, center, center);

      border: 2px solid $color-primary-dark-blue;
      color: $color-primary-dark-blue;
      padding: 10px;
      font-size: 16px;
      min-width: unset;
      width: max-content;
      border-radius: $border-radius;
      font-weight: 400;
      height: 51px;
    }

    .ncoded-checkbox {
      &__label {
        @extend %paragraph;
        margin-left: 12px;
      }

      .ncoded-checkbox__box {
        align-self: center;
      }
    }
  }
}
