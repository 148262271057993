@use '~scss/settings' as *;

.vacancy-information {
  max-width: 500px;
  margin: 0 auto;
  h5 {
    color: $color-text-dark-grey;
    @extend %paragraph-subtitle-small;
    margin-bottom: 16px;
  }
  ul {
    background-color: $color-white;
    box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.04),
      0px 4px 4px rgba(235, 235, 235, 0.08),
      0px 4px 14px rgba(231, 231, 231, 0.05), 0px 4px 5px rgba(0, 0, 0, 0.05);
    border-radius: $border-radius-10;
    padding: 16px 20px;
    color: $color-text-dark-grey;
    border: 1px solid $color-primary-light-teal;
    margin-bottom: 24px;
    li {
      p {
        &:first-of-type {
          @extend %input-descriptive;

          color: $color-grey-b5;
        }

        &:last-of-type {
          @include font(16px, 19px, 400);
          color: $color-text-dark-grey;
        }
      }
    }
  }
  &__actions {
    display: flex;
    @include gap(16px);
  }
}
