@use '~scss/color' as *;
@use '~scss/tools/tools' as *;
@use 'c-form-control' as *;

@mixin ncoded-textarea {
  .ncoded-textarea-container {
    &,
    &__wrapper {
      width: 100%;
      min-height: 124px;

      p {
        color: $color-text-light-grey;
      }

      &--error {
        color: $color-error !important;
        border-color: $color-error !important;
        textarea {
          background-color: $color-grey-fc;
        }
        .ncoded-textarea--error {
          background-color: $color-grey-fc;
          color: $color-error;
        }

        label,
        p {
          color: $color-error;
        }
      }
    }

    .ncoded-textarea {
      border: 1px solid $color-grey-d6;
      border-radius: 5px;
      flex: 1;
      padding: 15px;

      &--error {
        border-color: $color-error !important;
      }
    }

    &--inner {
      .ncoded-textarea-container__wrapper {
        margin-bottom: 0;
        padding-top: unset;
        border: 1px solid $color-grey-d6;
        background-color: $color-grey-fc;
        border-radius: 5px;
      }

      .ncoded-textarea {
        padding-left: 16px;
        border: none;

        ~ label {
          @include font(16px, 18px, 400);

          @extend %roboto;

          max-width: 75%;
          position: absolute;
          top: 16px;
          left: 16px;
          color: $color-text-light-grey;
          transform: none !important;
        }

        ~ p {
          @include font(16px, 18px, 400);

          @extend %roboto;

          position: absolute;
          top: 13px;
          right: 10px;
          margin: 0;
          height: max-content;
        }
      }
    }
  }
}
