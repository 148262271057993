@use '~scss/settings' as *;

.application-information-card {
  background: $color-white;
  border: 1px solid rgba(189, 216, 217, 0.78);
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.04),
    0px 4px 4px rgba(235, 235, 235, 0.08),
    0px 4px 14px rgba(231, 231, 231, 0.05), 0px 4px 5px rgba(0, 0, 0, 0.05);
  border-radius: $border-radius-10;
  padding: 32px 52px 46px 20px;

  ul {
    @include flex(column);

    gap: 4px;
    padding-left: 16px;
    margin-left: 16px;
    list-style-type: unset;

    li {
      @extend %paragraph;
      @extend %opensans;
    }
  }
}
