@use '~scss/settings' as *;

@include phablet() {
  .searchality-candidate-card {
    @include flex(column, space-between, stretch);

    min-height: 300px;

    &__left {
      border-bottom: 1px solid $color-primary-light-teal;
    }

    &__right {
      @include flex(column, flex-end, stretch);

      flex: 1;

      .candidate-qualification {
        align-items: flex-start;
      }
    }

    &--actions {
      .searchality-candidate-card__right {
        flex: 1;

        > section {
          > * {
            text-align: unset;
          }
        }
      }

      &:hover {
        .searchality-candidate-card__right {
          align-self: flex-start;
        }
      }
    }
  }
}
