@use '~scss/settings' as *;

.unsubscribe-modal {
  form {
    width: 100%;
  }

  p.description {
    margin-bottom: 16px;
  }

  p.descriptive {
    margin-top: 4px;
  }

  .checkbox-group-field {
    margin: 16px 0;
  }

  .actions {
    @include flex(row, flex-end, center);

    width: 100%;
    margin-top: 32px;
    gap: 8px;
  }
}
