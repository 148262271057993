@use '~scss/settings' as *;

@include phablet {
  .opportunity-card {
    @include flex(column, flex-start, flex-start);

    gap: 48px;

    &__left {
      max-width: unset;
      gap: 10px;

      &__top {
        &__school-information {
          gap: 12px;

          &__name {
            &::after {
              right: -8px;
            }
          }

          &__state {
            color: $color-secondary-light-grey;
          }
        }
      }

      &__bottom {
        &__start-date {
          p {
            color: $color-secondary-light-grey;
          }
        }
      }
    }

    &__right {
      .candidate-qualification {
        align-items: flex-start;
      }
    }
  }
}
