@use '~scss/settings' as *;

.dashboard {
  @include flex(column);

  position: relative;
  flex: 1;
  background-color: inherit;
}

.searchality-vertical-navigation {
  overflow: auto;
  align-self: baseline;
  padding: 32px;
  background-color: $color-primary-dark-blue;
  @include flex(column, center);
  height: 100%;
  width: 360px;
  a {
    padding: 8px 16px 8px 12px;
    border-radius: 10px;
    @include flex(row, flex-start, center);
    > svg {
      margin-right: 16px;
      circle {
        fill: $color-white;
      }
    }
    > span {
      @include font(16px, 22px, 600);
    }
    > div {
      margin-right: 16px;
      width: 24px;
      height: 24px;
      @include flex(row, center, center);
      svg {
        path {
          fill: $color-primary-light-teal;
        }
      }
    }
    color: $color-primary-light-teal;

    &.mandatory {
      svg {
        circle {
          fill: $color-orange-middle;
        }
      }
    }

    &.active {
      background-color: $color-white;
      color: $color-primary-dark-blue;
      svg {
        path {
          fill: $color-primary-dark-blue;
        }
      }
      > svg {
        circle {
          fill: $color-primary-dark-blue;
        }
      }
    }

    &.disabled {
      pointer-events: none;
      cursor: disabled;
    }
  }
}
