@use '~scss/settings' as *;

.change-email {
  &__form {
    @include flex(column);

    gap: 20px;
    margin-top: 28px;

    > button {
      margin-top: 12px;
    }

    .ncoded-button {
      align-self: baseline;
    }
  }

  &__confirm {
    background-color: $color-white;
    border-radius: $border-radius-10;
    padding: 26px 68px;

    > h1 {
      @extend %heading3;

      margin-top: 28px;
    }

    > p {
      margin-top: 20px;
      max-width: 360px;
    }

    > span {
      @include flex(row, center, center);
      @include gap(11px);

      margin-top: 25px;

      > span {
        @extend %paragraph-descriptive;
      }

      .link-button {
        @extend %paragraph-descriptive;

        border: none;
        color: $color-primary-teal;
        margin-top: 0;
        padding: 0;

        &,
        &:disabled {
          background-color: transparent;
        }

        &:disabled {
          opacity: 0.5;
        }
      }
    }

    .illustration-placeholder {
      @include size(152px, 161px);
    }
  }
}
