@use '~scss/settings' as *;

.login-information {
  &__email {
    margin-top: 60px;

    button {
      margin-top: 20px;
    }
  }

  &__info-text {
    .info-with-label__label {
      color: $color-grey-af;
    }

    p {
      color: $color-secondary-light-grey-2;
    }
  }

  &__password {
    margin-top: 30px;

    button {
      margin-top: 20px;
    }
  }
}
