@use '~scss/settings' as *;

.pricing-plan {
  &__cards {
    @include flex();
    @include gap(16px);

    min-height: 300px;
    margin-bottom: 16px;

    .card {
      flex: 1;

      p.card__description {
        @extend %paragraph-descriptive;

        color: $color-secondary-light-grey;

        > span {
          @include font-size(8px);
          @include size(8px);
        }
      }
    }

    ul {
      li {
        margin-bottom: 0;
      }
    }

    &--group {
      min-height: 360px;
    }

    &__left,
    &__right {
      position: relative;

      .pricing-plan__links {
        position: absolute;
        bottom: 16px;
      }
    }

    &__left {
      p {
        &.card__description {
          @include flex(row, flex-start, baseline);

          gap: 8px;
          margin-bottom: 12px;

          svg {
            @include size(8px);

            flex: 0 0 8px;
          }
        }

        &.card__desc {
          margin-bottom: 20px;
        }
      }
    }

    &__right {
      p.card__description {
        margin-bottom: 8px;
      }

      p.fee__description {
        @include flex(row, flex-start, baseline);
        @extend %paragraph-descriptive;

        color: $color-secondary-light-grey;

        > span {
          @include font-size(8px);
          @include size(8px);
        }

        gap: 8px;
        margin: auto 0 12px;
      }
    }
  }

  &__links {
    @include flex(row, flex-start, center);

    @extend %paragraph-subtitle-small;

    gap: 4px;
    color: $color-primary-dark-blue;
  }

  &__note {
    @include font(8px, 12px, 300);
    margin: 0 0 24px 0;
    min-height: 48px;
  }
}
