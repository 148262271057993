@use '~scss/settings' as *;

.upload-school-logo {
  margin-top: 32px;
  max-width: 425px;

  &__title {
    @extend %paragraph-subtitle-small;

    margin-bottom: 20px;
  }

  &__hint-container {
    @include flex(row, flex-start, center);

    margin: 12px 0;
    gap: 9px;
    color: $color-secondary-light-grey;

    p {
      @extend %paragraph-descriptive;

      color: $color-text-dark-grey;
    }
  }
}
