@use '~scss/settings' as *;

.application-upload-documents {
  max-width: 450px;
  width: 100%;

  > p {
    @extend %paragraph-descriptive;

    margin-bottom: 37px;
  }

  > h1 {
    @extend %paragraph-subtitle;

    margin-bottom: 8px;
  }

  > form {
    width: 100%;

    > p {
      @extend %paragraph-descriptive;

      margin-top: 20px;
    }
  }

  button[type='submit'] {
    margin-top: 20px;
  }

  &__list {
    @include flex(column);

    gap: 20px;
  }

  &__questions {
    margin-top: 26px;

    > p {
      @extend %heading4;

      margin-bottom: 20px;
    }

    &__question {
      @include flex(column);
      border-radius: $border-radius-6;
      border: 1px solid $color-primary-light-teal;
      background: $color-white;
      padding: 12px;
      gap: 16px;

      > p {
        @extend %paragraph-subtitle-small;
      }

      .ncoded-input {
        border-color: $color-primary-light-teal;
      }
    }

    .application-upload-documents__questions__question:not(:first-of-type) {
      margin-top: 12px;
    }
  }
}
