@use '~scss/settings' as *;

@include phablet {
  .account-edit-wrapper {
    @include flex(column-reverse);

    &__header {
      &__text {
        display: none;
      }

      &__actions {
        @include flex(column);

        width: 100%;

        button[class*='ncoded-button'] {
          width: 100%;
        }
      }
    }

    &__content {
      width: 100%;
      max-width: unset;
    }
  }
}
