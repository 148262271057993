@use '~scss/settings' as *;

.salary-form-fields {
  > * {
    margin-bottom: 30px;
  }

  .disable-slider {
    pointer-events: none;
  }

  > div {
    > label {
      @extend %opensans;
    }

    > div {
      > label {
        @extend %opensans;
      }
    }
  }

  &__currency {
    .form-field-label {
      margin-bottom: 12px;
    }
  }

  &__school-pays {
    .toggle-field__group {
      @include flex(column-reverse);
      @include gap(unset, row-reverse);

      gap: 16px;
    }
  }

  &__salary {
    @include flex(column);

    gap: 24px;

    p {
      @include flex(row, flex-start, center);

      svg {
        margin-left: 16px;
        cursor: pointer;
        fill: $color-secondary-light-grey-2;
      }
    }

    &__inputs {
      @include flex;

      > * {
        flex: 1;
      }

      gap: 24px;
    }

    &__boarding {
      .boarding-description {
        @extend %paragraph-descriptive;

        margin: 12px 0;
        color: $color-secondary-light-grey;
      }

      > h5:first-of-type {
        margin-bottom: 16px;
      }
    }
  }

  p {
    @extend %paragraph-subtitle-small;

    max-width: 80%;
  }
}
