@use '~scss/settings' as *;

.vacancy-expired-update-modal {
  .ncoded-modal__content {
    main {
      form {
        @include flex(column);

        width: 100%;
        gap: 16px;

        .actions {
          @include flex(row, flex-end);

          gap: 16px;
        }
      }
    }
  }
}
