@use '~scss/settings' as *;

.school-type {
  h1 {
    margin-bottom: 8px;
  }
  p.description {
    @include flex(row, flex-start, center);

    gap: 4px;

    svg {
      @include size(16px);

      fill: $color-secondary-light-grey;
    }
  }
}
