@use '~scss/settings' as *;

.checkbox-with-input-group-field {
  @include flex(column);

  gap: 12px;

  &--error {
    @extend %input-descriptive;

    margin: 10px 0 4px 4px;
    color: $color-error;
  }

  &__list {
    @include flex(column);

    & + .checkbox-with-input-group-field__input-container {
      .s-input-wrapper {
        &::after {
          height: 100px;
        }
      }
    }

    gap: 12px;
    width: 90%;
    align-self: flex-end;
    position: relative;

    &__li {
      border: 1px solid $color-primary-light-teal;
      border-radius: $border-radius-6;
      background-color: $color-white;
      padding: 16px 12px;
      position: relative;

      &::after {
        @include size(13px, 30px);

        content: '';
        position: absolute;
        top: 50%;
        left: -26px;
        transform: translate(0, -100%);
        border-bottom: 1px solid $color-primary-light-teal;
        border-left: 1px solid $color-primary-light-teal;
        border-bottom-left-radius: 6px;
      }

      &:not(:first-of-type) {
        &::after {
          height: 100px;
        }
      }

      > p {
        @extend %paragraph-subtitle-small;

        > span {
          color: $color-secondary-light-grey;
        }
      }

      button.svg-button-wrapper {
        @extend %paragraph-descriptive;

        height: unset;
        color: $color-anchor;
        text-decoration: underline;
        padding: 0;
        margin-top: 8px;
      }
    }
  }

  &__input-container {
    @include flex(row, flex-start, center);

    width: 90%;
    align-self: flex-end;
    position: relative;
    gap: 8px;

    > button {
      svg {
        @include size(16px);

        path {
          stroke: $color-white;
          stroke-width: 3;
        }
      }
    }
  }

  &__checkbox {
    padding: 16px 12px;
    border-radius: $border-radius-6;
    border: 1px solid $color-primary-light-teal;
    background: $color-white;

    &__input {
      input {
        position: absolute;
        opacity: 0;

        &:checked + label:before {
          background-color: $color-secondary-light-grey;
        }

        &:checked + label::after {
          display: block;
        }
      }

      label {
        display: inline-block;
        cursor: pointer;

        svg {
          @include size(14px);

          path {
            stroke-width: 3;
            stroke: $color-secondary-light-grey;
          }
        }
      }

      &--wrapper {
        @include flex(row, space-between, center);
      }

      &--label {
        @include flex(column);

        gap: 16px;

        > label {
          @extend %paragraph-subtitle-small;
        }

        > p {
          @extend %paragraph-descriptive;
        }
      }
    }
  }

  .s-input-wrapper {
    flex: 1;

    .ncoded-input--inner > label {
      font-size: 11px;
    }

    &::after {
      @include size(13px, 30px);

      content: '';
      position: absolute;
      top: 50%;
      left: -25px;
      transform: translate(0, -100%);
      border-bottom: 1px solid $color-primary-light-teal;
      border-left: 1px solid $color-primary-light-teal;
      border-bottom-left-radius: 6px;
    }
  }
}
