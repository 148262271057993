@use '~scss/settings' as *;

.requested-document {
  @include flex(row, space-between, center);

  padding: 16px 12px;
  border-radius: $border-radius-6;
  border: 1px solid $color-primary-light-teal;
  background: $color-white;

  > svg {
    path {
      fill: $color-primary-teal;
    }
  }

  &__action {
    @include flex(column);

    gap: 8px;

    > p {
      @extend %paragraph-subtitle-small;
    }
  }

  &--wrapper {
    @include flex(column);

    gap: 4px;

    > p {
      @extend %paragraph-descriptive;

      font-style: italic;
    }
  }

  button.svg-button-wrapper {
    @extend %paragraph-descriptive;

    padding: 0;
    min-height: unset;
    height: unset;
    color: $color-anchor;
    text-decoration: underline;
  }
}

.request-again-modal {
  .ncoded-modal__content {
    max-width: 500px;

    main {
      form {
        @include flex(column);

        gap: 20px;
        width: 100%;
      }
    }
  }
}
