@use '~scss/settings' as *;

@media (max-width: 901px) {
  details {
    &[open] {
      summary {
        svg {
          transform: rotate(180deg);
        }
      }
    }

    > summary:first-of-type {
      display: block;
    }
  }

  .applications-filters {
    @include flex(column, flex-start, stretch);
    @include gap(0);

    gap: 8px;

    &__outside {
      display: none;
    }

    .ncoded-select {
      width: 100%;

      &__content {
        width: unset;
        max-width: 90%;

        + svg {
          margin-left: auto;
        }
      }
    }

    &__filter-portal {
      .dropdown__content {
        max-width: 90%;
      }
    }

    &__wrapper {
      @include flex(column, center, flex-start);

      margin-top: 12px;

      button {
        align-self: center;
      }
    }

    &__trigger {
      @include flex(row, center, center);

      color: $color-primary-dark-blue;
      background-color: $color-white;
      border-radius: $border-radius-10;
      border: 1px solid $color-primary-light-teal;
      width: 100%;
      gap: 12px;
      padding: 12px 0;
      margin: auto;
      margin-bottom: 20px;
    }
  }
}
