@use '~scss/settings' as *;

.teacher-profile-build__start {
  @include flex(column, flex-start, flex-start);

  > button.get-started {
    max-width: 700px;
  }

  flex: 1;

  &__content {
    @include flex(column, center, flex-start);

    margin-top: 140px;
    max-width: 500px;

    h3 {
      @include font(32px, 38px, 800);

      margin-bottom: 8px;
    }

    p {
      @include font(16px, 26px, 400);

      color: $color-secondary-light-grey;
    }
  }

  .ncoded-button {
    margin-top: 56px;
  }

  &__flow {
    @include flex(column);

    margin-top: 32px;

    li {
      > svg {
        @include size(100%);
      }

      p {
        @extend %paragraph-descriptive;

        text-align: center;
      }
    }

    &__stickers {
      display: grid;
      align-items: center;
      justify-content: center;
      grid-template-columns: repeat(4, 82px);
      column-gap: 40px;

      > span {
        @include flex(row, center, center);
        @include size(64px);

        border-radius: 21px;
        padding: 16px;
        margin: 0 auto;
        position: relative;

        > svg {
          @include size(100%);
        }

        &:not(:first-of-type)::before {
          content: '';
          position: absolute;
          height: 2px;
          width: 48px;
          background-color: #cecece;
          left: -52px;
          border-radius: 20px;
        }
      }

      &__personal {
        background-color: $color-primary-light-teal;
      }

      &__education {
        background-color: $color-orange-soft;

        path {
          fill: $color-orange-dark;
        }
      }

      &__referrals {
        background-color: rgba($color: $color-secondary-grey, $alpha: 0.21);

        path {
          fill: $color-secondary-grey;
        }
      }

      &__positions {
        background-color: rgba($color: $color-primary-dark-blue, $alpha: 0.21);

        path {
          fill: $color-primary-dark-blue;
        }
      }
    }

    &__names {
      display: grid;
      grid-template-columns: repeat(4, 82px);
      column-gap: 40px;
      justify-content: space-between;
    }
  }
}
