@use '~scss/settings' as *;

.vacancy-preview-card {
  @include flex(row, space-between, center);

  position: fixed;
  z-index: 5;
  top: 0;
  left: 0;
  right: 0;
  background-color: white;
  min-height: 160px;
  padding: 35px 170px;
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.04),
    0px 4px 4px rgba(235, 235, 235, 0.08),
    0px 4px 14px rgba(231, 231, 231, 0.05), 0px 4px 5px rgba(0, 0, 0, 0.05);

  &__text {
    @include flex(column);

    flex-basis: 40%;
    gap: 10px;

    &__title {
      @extend %paragraph-subtitle;
    }
  }

  &__actions {
    @include flex;

    gap: 12px;
  }
}
