@use '~scss/settings' as *;

.application-empty-state {
  &.template-illustration {
    h4 {
      color: $color-primary-dark-blue;
    }

    p {
      max-width: unset;
    }

    .template-illustration__image-container {
      max-width: 460px;
    }
  }
}
