@use '~scss/settings' as *;

@media (max-width: 500px) {
  .opportunity-metadata {
    padding: 20px;

    &__top {
      &__school-information {
        @include flex(column, flex-start, flex-start);
      }
    }

    &__bottom {
      span {
        svg {
          @include size(16px);
        }
      }

      label {
        @include font-size(12px, 16px);
      }

      p {
        @include font-size(12px, 16px);
      }
    }
  }
}
