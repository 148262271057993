@use '~scss/settings' as *;

.seachality-profile-actions {
  @include flex(column, flex-end, flex-end);

  &__buttons {
    @include flex(row, flex-end, flex-start);

    gap: 12px;
  }

  .title {
    color: $color-secondary-light-grey;
  }

  position: absolute;
  right: 0;

  button {
    width: 226px;
    justify-content: space-between;
  }

  .delete-candidate {
    border-color: $color-error;

    svg {
      fill: $color-error;
    }
  }

  svg {
    fill: $color-primary-dark-blue;
  }

  .transparent {
    &,
    path {
      fill: none;
    }
  }

  &--only-icons {
    button.ncoded-button {
      border: none;
      padding: unset;
      background-color: transparent;

      svg {
        fill: $color-secondary-light-grey;
        stroke: $color-secondary-light-grey;

        path {
          fill: $color-secondary-light-grey;
        }
      }

      &.delete-candidate {
        svg,
        path {
          fill: $color-error;
          stroke: none;
        }
      }

      .transparent {
        &,
        path {
          fill: none;
        }
      }

      svg,
      path {
        &:hover {
          fill: $color-primary-teal;
          stroke: $color-primary-teal;
        }
      }
    }
  }
}
