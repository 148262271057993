@use '~scss/settings' as *;

.member-cards {
  @include flex(column, flex-start, stretch);

  width: 100%;
  list-style: none;
  margin: 16px 0 48px;

  > li {
    margin-bottom: 0;

    > span {
      > strong {
        font-weight: 500;
      }
    }
  }

  li:last-of-type {
    border-bottom: 1px solid $color-primary-light-teal;
  }
}
