@use '~scss/settings' as *;

@include phablet() {
  .main-layout {
    main {
      overflow: hidden;
      padding: 32px 8px;

      article {
        overflow: hidden;
      }
    }
  }
}

@media (max-width: 370px) {
  .main-layout {
    &.auth-background {
      background-size: 120%;
    }
  }
}
