@use '~scss/settings' as *;

.spb-host-country-requirements {
  .description {
    @extend %paragraph-descriptive;

    margin-top: 8px;
  }

  .header-text {
    @extend %paragraph-subtitle;
    + .description {
      margin-bottom: 14px;
    }
  }

  &__field-container {
    .s-input-wrapper,
    .s-select-wrapper,
    .yes-no-explain-field,
    .checkbox-field {
      margin-top: 12px;
    }
  }

  .distinct-container {
    margin-top: 24px;

    .s-select-wrapper {
      margin-top: 20px;
    }
  }

  .form-field-label {
    span {
      @extend %paragraph-descriptive;
    }
  }
}
