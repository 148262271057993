@use '~scss/settings' as *;

.vacancy-candidates-empty-state {
  .banner__header__icon {
    svg {
      fill: $color-primary-dark-blue;
      path {
        fill: $color-primary-dark-blue;
      }
    }
    &::before {
      background-color: $color-primary-light-teal;
    }
  }
  @include gap(28px, column);
  ol {
    @extend %paragraph;
  }
}
