@use '~scss/settings' as *;

@include phablet() {
  .member-vacancy-card {
    .searchality-vacancy-card {
      &__right {
        align-items: flex-start;
      }
    }
  }
}
