@use '~scss/settings' as *;

@include phablet() {
  .school-card {
    @include flex(column, space-between, flex-start);

    gap: 4px;

    &__name {
      max-width: 90%;
    }
  }
}
