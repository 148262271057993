@use '~scss/settings' as *;

.target-positions {
  height: 100%;
  @include flex(column);

  .ncoded-checkbox {
    @include flex(row-reverse, space-between, center);

    gap: 12px;
    padding: 20px 12px;
    border-radius: $border-radius-6;
    border: 1px solid $color-primary-light-teal;
    background: $color-white;

    .ncoded-checkbox__box {
      align-self: center;

      + * {
        margin-left: unset;
      }
    }

    &__label {
      @extend %paragraph;

      color: $color-primary-dark-blue;
    }

    &--checked {
      border-color: $color-primary-dark-blue;
    }
  }
}
