@use '~scss/settings' as *;

.text-editor-field {
  &__editor-class {
    background-color: $color-white;
    padding: 1rem;
    border: 1px solid #ccc;
    border-radius: $border-radius-5;
    margin-top: 12px;
    min-height: 130px;
  }

  &__toolbar-class {
    border: 1px solid #ccc;
  }

  &__error {
    @extend %input-descriptive;

    color: $color-error;
    margin: 10px 0 4px 4px;
  }
}
