@use '~scss/settings' as *;

.edit-association-list {
  @include flex(column, center, stretch);

  flex: 1;
  width: 100%;
  padding: 24px;
  background-color: $color-white;
  border-radius: $border-radius-10;

  > form {
    max-width: 500px;
    width: 100%;
    margin: 0 auto;

    h1 {
      @extend %paragraph-subtitle;

      white-space: pre-wrap;
    }

    .error {
      color: $color-error;
    }

    p.description {
      @extend %paragraph-descriptive;

      margin: 8px 0 24px;
    }
  }
}
