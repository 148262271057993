@use '~scss/settings' as *;

.vacancy-settings-information {
  width: 100%;
  background-color: $color-white;
  padding: 24px 20px;
  border: 1px solid $color-primary-light-teal;
  border-radius: $border-radius-10;

  &__title {
    @include flex(row, flex-start, center);

    gap: 10px;
    margin-bottom: 12px;

    svg {
      #Vector {
        fill: $color-secondary-light-grey;
      }
    }

    p {
      @extend %paragraph-subtitle;
    }
  }

  &__list-title {
    @extend %heading4;

    margin-bottom: 8px;
  }

  > ol {
    padding-inline-start: 30px;

    + p {
      margin-top: 20px;
    }
    li {
      margin-bottom: 8px;
    }
  }

  > button.ncoded-button {
    margin-top: 20px;
    min-width: 110px;
  }
}
