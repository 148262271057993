@use '~scss/settings' as *;

@include phablet() {
  .searchality-heading {
    @include flex(column, flex-start, flex-start);

    margin-bottom: 20px;

    h1 {
      @include font-size(24px, 28px);
    }
  }
}
