@use '~scss/settings' as *;

.application-card {
  @include flex(row, space-between, flex-start);

  padding: 20px;
  background: $color-white;
  border: 1px solid rgba(189, 216, 217, 0.78);
  cursor: pointer;
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.04),
    0px 4px 4px rgba(235, 235, 235, 0.08),
    0px 4px 14px rgba(231, 231, 231, 0.05), 0px 4px 5px rgba(0, 0, 0, 0.05);
  border-radius: $border-radius-10;
  max-width: 100%;
  margin-bottom: 20px;

  p {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &__information {
    @include flex(column, space-between, flex-start);

    gap: 44px;
    max-width: 70%;

    &__position {
      max-width: 100%;

      &__school {
        @extend %paragraph-subtitle-small;
        @extend %opensans;

        color: $color-secondary-light-grey;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      &__title {
        @extend %paragraph-subtitle;

        color: $color-text-dark-grey;
        margin-top: 4px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      &__subject-grade {
        @extend %paragraph-descriptive;

        color: $color-secondary-light-grey;
        margin-top: 4px;
      }
    }

    &__date {
      @include flex;

      gap: 4px;

      label {
        @extend %paragraph;

        color: $color-text-light-grey;
      }
    }
  }

  &__status {
    @include flex(row, center, center);

    gap: 8px;

    label {
      @extend %paragraph-descriptive;

      color: $color-text-light-grey;
    }
  }

  &:hover {
    background: $color-grey-f7;
    border: 1px solid $color-primary-teal;
  }
}
