@use '~scss/settings' as *;

.add-vacancy-question-video {
  @include flex(column, center, center);
  position: relative;

  button.go-back-button {
    position: absolute;
    top: 0;
    left: 0;
    padding: 0;
    height: unset;
  }

  &__form {
    max-width: 530px;

    &__title {
      @extend %paragraph-subtitle;

      margin-bottom: 8px;
    }

    .description {
      @extend %input-descriptive;
    }

    &__questions {
      margin-top: 40px;
    }

    &__video {
      margin-top: 40px;
    }

    .title {
      @extend %heading4;
      margin-bottom: 8px;
    }

    > button {
      margin-top: 50px;
    }
  }
}
