@use '~scss/settings' as *;

.education-field {
  > p {
    @extend %input-descriptive;

    color: $color-error;
    margin-top: 8px;
  }
}
