@use '~scss/settings' as *;

.tpb-top-actions {
  @include flex(row, space-between, center);
  width: 100%;
  &__right {
    @include flex(row, flex-end, center);

    @include gap(8px);
    > svg {
      circle {
        fill: $color-primary-orange;
      }
    }
    > p {
      color: $color-primary-orange;
      @extend %paragraph-subtitle-small;
    }
    button {
      @extend %paragraph-subtitle-small;
      height: 38px;
    }
  }
  &--no-go-back {
    @include flex(row, flex-end, center);
  }

  .ncoded-button--outline {
    border: 2px solid $color-text-light-grey;
  }

  .profile-status {
    @extend %paragraph-descriptive;
    color: $color-text-light-grey;
    strong {
      margin-left: 8px;
      @extend %paragraph-subtitle-small;
      color: $color-primary-teal;
    }
  }
}
