@use '~scss/settings' as *;

@media (max-width: 1210px) {
  .candidate-profile {
    @include flex(column, flex-start);
    @include gap(unset);

    padding: unset;
    gap: 32px;

    margin: 0 auto;

    &__left {
      @include flex(column);
      @include gap(20px, column);

      min-width: 100%;
      max-width: 100%;
    }

    &__right {
      margin-top: 32px;
      max-width: unset;
      width: unset;
    }
  }
}
