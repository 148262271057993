@use '~scss/settings' as *;

.searchality-profile {
  @include flex(column);

  width: 100%;
  margin-top: 108px;

  .language-title {
    color: $color-secondary-light-grey;
  }

  &-tabs {
    margin-top: 32px;
  }

  &__error {
    margin-bottom: 42px;
  }

  .profile-section {
    @include flex(column);

    gap: 16px;
    padding: 32px;

    &__title {
      @include flex(row, flex-start, center);

      gap: 8px;

      svg {
        @include size(20px);
      }

      p {
        @extend %heading4;

        color: $color-primary-dark-blue;
      }
    }

    a {
      @extend %paragraph;
    }
  }
  .grey-icon {
    path {
      fill: $color-secondary-light-grey;
    }
  }
}

@media (max-width: 1210px) {
  .searchality-profile {
    max-width: unset;
  }
}
