@use '~scss/settings' as *;

.add-comment {
  @include flex(row);

  gap: 20px;

  &__left {
    p {
      @extend %paragraph-subtitle-small;
    }
  }

  &__right {
    @include flex(row, center, center);

    gap: 20px;
    flex: 1;

    button.add-comment__right__send {
      @include size(32px);

      border-radius: 50%;
      align-self: flex-end;

      svg {
        margin: 0;
      }
    }

    textarea {
      @extend %input-descriptive;
      @extend %roboto;

      width: 100%;
      padding: 0;
      border: none;
      outline: none;
      resize: vertical;
      margin-top: 14px;
      min-height: 54px;

      &::placeholder {
        color: $color-grey-b5;
      }
    }
  }
}
