@use '~scss/settings' as *;

.widget-url-input {
  @include flex(column, flex-start, flex-start);

  gap: 16px;

  > p:first-of-type {
    @extend %paragraph-subtitle-small;

    color: $color-text-dark-grey;
  }

  > p:last-of-type {
    @extend %paragraph-descriptive;

    width: 100%;
    max-width: 600px;
  }

  .s-input-wrapper {
    width: 100%;
    max-width: 600px;
  }
}
