@use '~scss/settings' as *;

.integration {
  @include gap(32px, column);

  &__how-to-finalize {
    position: relative;

    button {
      position: absolute;
      right: 10px;
      top: 20px;
      border: none;
      padding: 4px;
    }
  }

  section {
    @include flex(column);
    @include card;

    gap: 16px;
    flex: 1;

    h1 {
      @extend %heading3;
    }

    code.descriptive {
      background-color: $color-gray-f6;
      border-radius: 4px;
      padding: 0 4px;
    }

    p.description {
      @extend %paragraph;

      color: $color-secondary-light-grey;
    }

    p.info {
      @include flex(row, flex-start, center);

      gap: 8px;
      padding: 8px 12px;
      border: 1px solid $color-primary-teal;
    }

    p.title {
      color: $color-primary-dark-blue;
    }

    a {
      @extend %paragraph;
    }

    .explanations {
      @include flex(column);

      gap: 16px;
    }

    .code-wrapper {
      @include flex(row, flex-start, center);

      gap: 16px;
      padding-left: 32px;

      &__section {
        @include flex(column);

        gap: 16px;
        flex: 1;

        ol {
          list-style: decimal;

          li {
            & + li,
            > * + * {
              margin-top: 20px;
            }

            > div {
              position: relative;
              flex: 1;
              background-color: $color-gray-f6;
              border-radius: 4px;
              padding: 12px;

              button {
                position: absolute;
                right: 0;
                top: 0;
                border: none;
                padding: 4px;
              }
            }
          }
        }
      }
    }
  }

  &__widget-status {
    p {
      @include flex(row, space-between, center);

      gap: 32px;

      strong {
        max-width: 70%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }

    span {
      text-align: right;

      &.orange {
        color: $color-primary-orange;
      }

      &.blue {
        color: $color-primary-dark-blue;
      }
    }
  }
}
