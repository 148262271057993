@use '~scss/settings' as *;

.your-profile {
  display: grid;

  &__subtitle {
    @extend %paragraph-subtitle-small;
  }

  .form-field-label {
    margin-bottom: 0;
  }

  &__description {
    @extend %paragraph-descriptive;
    width: 50%;
    margin-top: 4px;
  }

  > div {
    margin-top: 16px;
  }

  &__tippy-container {
    @include flex(row, flex-start, center);
    @include gap(4px);

    @extend %paragraph-subtitle-small;
    @extend %opensans;

    margin-top: 24px;

    small {
      margin-left: 4px;
      color: $color-text-light-grey;
    }

    svg {
      fill: $color-secondary-light-grey;
      margin-left: 4px;
    }
  }

  &__textarea {
    width: 50%;
  }
  > h5 {
    margin-bottom: 16px;
    &:not(:first-of-type) {
      margin-top: 48px;
    }
  }
}
