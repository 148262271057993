@use '~scss/settings' as *;

@include phablet() {
  .members-filters {
    @include flex(column);

    &__select-wrapper {
      @include flex(column, flex-start, stretch);
    }

    .ncoded-select {
      width: unset;

      &__content {
        width: unset;

        + svg {
          margin-left: auto;
        }
      }
    }
  }
}
