@use '~scss/settings' as *;

@include phablet() {
  .application-card {
    @include flex(column, space-between, flex-start);

    &__information {
      max-width: 100%;

      &__date {
        margin-bottom: 20px;
      }
    }

    &__status {
      label {
        display: none;
      }
    }
  }
}
