@use '~scss/settings' as *;

.main-layout {
  @include flex(column);

  flex: 1;
  background: inherit;
  background-color: inherit;

  main {
    @include flex(row);

    flex: 1;
    padding: 48px 16px;

    article {
      flex: 1;
      display: flex;
      width: 100%;
    }
  }

  &.auth-background {
    background: unset;
    background: $color-gray-f5 url(/assets/images/signup-image.svg) no-repeat;
    background-position: 0 100%;
    background-size: 100%;

    @include phablet-portrait {
      background-image: url(/assets/images/signup-image-mobile.svg);
    }
  }

  button.ncoded-button {
    width: max-content;
  }
}
