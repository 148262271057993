@use '~scss/settings' as *;

.scrollable-layout {
  display: flex;
  flex-direction: column;
  -webkit-box-pack: start;
  justify-content: flex-start;
  -webkit-box-align: stretch;
  align-items: stretch;
  width: 100%;
  height: 100%;
  main {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 320px 1fr;
    grid-template-rows: 1fr;
    overflow: hidden;
  }
}
