@use '~scss/settings' as *;

@include phablet {
  .education-certificates__cards {
    grid-template-columns: 1fr;
  }

  .education-certificates-modal {
    &__date-range,
    &__basic-info__first-row,
    &__basic-info__second-row {
      @include flex(column);
    }
  }
}
