@use '~scss/settings' as *;

.searchality-candidate-card {
  @include flex(row, flex-start, center);
  @include card;

  min-height: 170px;
  margin-bottom: 20px;

  &:hover {
    border-color: $color-grey-f7;
    background: $color-white;
  }

  .photo-preview {
    width: 96px;
    height: 96px;
    margin-right: 24px;
  }

  &__left {
    @include flex;
    flex: 1;

    &__info {
      @include flex(column);

      gap: 12px;
      flex: 1;

      h1 {
        @include flex(row, flex-start, center);

        @extend %paragraph-subtitle;

        svg {
          @include size(24px);
        }
      }

      &__applied {
        @include flex;
      }

      p {
        @extend %paragraph;

        color: $color-text-light-grey;

        &:last-of-type {
          color: $color-text-dark-grey;
        }
      }

      &__status {
        @extend %paragraph-descriptive;

        color: $color-primary-teal;
      }
    }
  }

  &__right {
    max-width: 300px;
  }

  &--actions {
    .searchality-candidate-card__right {
      > section {
        display: block;

        > * {
          text-align: right;
        }
      }

      .seachality-profile-actions {
        display: none;
        position: relative;
        z-index: 20;
      }
    }

    &:hover {
      .searchality-candidate-card__right {
        align-self: flex-end;

        > section {
          display: none;
        }

        .seachality-profile-actions {
          display: flex;
        }
      }
    }
  }
}
