@use '~scss/settings' as *;

.uploaded-requested-document {
  @include flex(row, space-between, center);

  gap: 15px;
  padding: 10px;
  border-radius: $border-radius;
  border: 1px solid $color-primary-light-teal;
  background: $color-white;
  color: $color-secondary-light-grey;

  &--deleted {
    background-color: $color-gray-e8;
  }

  &__info {
    @include flex(column);

    gap: 6px;
    flex: 1;
    color: $color-secondary-light-grey;

    &__name {
      @extend %paragraph-subtitle-small;
    }

    &__size {
      @extend %paragraph-descriptive;
    }
  }

  &__dots {
    @include flex(column);

    gap: 2px;

    span {
      @include size(3px);

      border-radius: 50%;
      background-color: $color-secondary-light-grey;
    }

    width: 8px;
  }

  &__actions {
    @include flex(column);

    width: 150px;

    &__action {
      @include flex(row, flex-start, center);

      gap: 8px;
      color: $color-secondary-light-grey;
      cursor: pointer;
      padding: 5px 6px 5px;

      &:hover {
        background-color: $color-gray-ea;
      }

      &:first-of-type {
        padding-top: 8px;
      }

      &:last-of-type {
        padding-bottom: 8px;
      }

      svg {
        @include size(20px);

        @include flex(row, flex-start, center);
      }

      p {
        @extend %paragraph-descriptive;
        color: currentColor;
      }
    }
  }
}
