@use '~scss/settings' as *;

.scrollable-layout {
  @include phablet() {
    aside {
      display: none;
    }

    main {
      @include flex(column, flex-start, stretch);

      overflow: auto;
    }
  }
}
