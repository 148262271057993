@use '~scss/settings' as *;

.group-settings {
  @include flex(row, flex-start, space-between);

  flex: 1;
  gap: 36px;
  flex-wrap: wrap;

  > * {
    flex: 1 1 45%;
    max-width: 450px;
    width: 50%;
  }

  h5 {
    @extend %paragraph-subtitle;

    color: $color-secondary-light-grey-2;
  }

  .upload-school-logo {
    margin-top: 0;

    &__title {
      @extend %paragraph-subtitle;

      color: $color-secondary-light-grey-2;
    }
  }

  .widget-url-input {
    > p:first-of-type {
      @extend %paragraph-subtitle;

      color: $color-secondary-light-grey-2;
    }
  }
}
