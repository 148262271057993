@use '~scss/settings' as *;

.checkbox-group-field {
  @include flex(column);

  width: 100%;
  gap: 12px;

  .ncoded-checkbox__label {
    font-size: 16px;
    font-weight: 400;
  }
}
