@use '~scss/settings' as *;

.linked-association-empty-state {
  @include flex(column);

  gap: 14px;

  &__title {
    @extend %paragraph-subtitle;

    color: $color-secondary-light-grey-2;
  }

  &__description {
    @extend %paragraph-descriptive;

    a {
      color: $color-primary-teal;

      text-decoration: underline;
    }
  }
}
