@use '~scss/settings' as *;

.edit-personal-information {
  &--loading {
    opacity: 0.4;
    pointer-events: none;
  }
  &__form {
    @include flex(column);

    position: relative;

    gap: 22px;
    margin-top: 28px;

    > button {
      align-self: flex-end;
    }

    &__photo {
      > p {
        @extend %paragraph-subtitle-small;
        @extend %opensans;

        color: $color-text-dark-grey;

        span {
          color: $color-text-light-grey;
        }

        margin-bottom: 12px;
      }
    }
  }
}
