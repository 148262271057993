@use '~scss/settings' as *;

.matched-opportunities-empty-state {
  h4 {
    color: $color-secondary-light-grey;
    max-width: 350px;
    text-align: center;
  }

  .template-illustration__image-container {
    max-width: 650px;
  }

  > p {
    color: $color-primary-dark-blue;
    max-width: 520px;
  }

  &__button {
    align-self: center;

    margin-top: 48px;
  }
}
