@use '~scss/settings' as *;

.box-filters {
  @include flex(row, space-between);

  gap: 24px;
  overflow: auto;

  &--loading {
    opacity: 0.5;
  }

  .box {
    @include flex(column, center, center);
    @include card;

    aspect-ratio: 1;
    max-width: 162px;
    flex: 1;
    padding: 8px;
    opacity: 0.5;

    &--clickable:hover {
      h2,
      p {
        color: $color-primary-teal;
      }
    }

    h2 {
      @include flex;
      @include font(40px, 47px, 900);

      gap: 4px;

      color: $color-secondary-light-grey;
      margin-bottom: 16px;
      svg {
        align-self: center;
      }
    }

    p {
      @extend %paragraph-subtitle-small;

      text-align: center;
      color: $color-secondary-light-grey;
      padding: 0 8px;
    }

    span {
      @extend %paragraph-descriptive;
      color: $color-secondary-light-grey;

      margin-top: 2px;
    }

    svg {
      @include size(24px);
    }

    &--active {
      background-color: $color-primary-dark-blue;

      h2,
      p {
        color: $color-primary-light-teal;
      }

      svg {
        path {
          fill: $color-primary-light-teal;
        }
      }
    }

    &--active,
    &--clickable {
      cursor: pointer;
      opacity: 1;
      &:hover {
        h2,
        p {
          color: $color-primary-teal;
        }
      }
    }
  }
}
