@use '~scss/settings' as *;

.searchality-review-card-item {
  @include flex(column);

  > * + * {
    margin-top: 8px;
  }

  label {
    @extend %input-descriptive;

    color: $color-grey-b5;
  }

  p {
    white-space: pre-wrap;
    overflow-wrap: break-word;
  }

  &__description,
  &__subtitle {
    @extend %paragraph-descriptive;

    color: $color-secondary-light-grey;
  }

  &__subtitle {
    margin-top: 2px;
  }

  .orange {
    color: $color-primary-orange;
  }

  .blue {
    color: $color-primary-teal;
  }
}
