@use '~scss/settings' as *;

.vacancy-matching-toggle-section {
  @include flex(row, space-between, center);

  margin-top: 20px;
  width: 100%;
  padding: 28px 20px;
  background-color: $color-white;
  border-radius: $border-radius-10;
  border: 1px solid rgba(189, 216, 217, 0.78);
  /* Button drop shadow */
  box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.05),
    0px 4px 14px 0px rgba(231, 231, 231, 0.05),
    0px 4px 4px 0px rgba(235, 235, 235, 0.08),
    0px 8px 10px 0px rgba(0, 0, 0, 0.04);

  &__text-container {
    @include flex(column, flex-start, flex-start);

    gap: 12px;

    &__subtitle {
      @extend %paragraph-descriptive;
      max-width: 90%;
    }

    .form-field-label {
      @extend %heading4;

      svg {
        fill: $color-secondary-light-grey;
      }
    }
  }
}
