@use '~scss/settings' as *;

.dropzone {
  @include flex(column);

  gap: 16px;

  &__links {
    @include flex(column);

    gap: 12px;
  }

  &__upload {
    @include flex(column, center, center);

    --dropzone-action-color: #{$color-primary-light-teal};
    --dropzone-background-color: #{$color-grey-fc};
    --dropzone-text-color: #{$color-black};
    border: 2px dashed var(--dropzone-action-color);
    border-radius: $border-radius-20;
    color: var(--dropzone-text-color);
    background-color: var(--dropzone-background-color);
    width: fit-content;
    padding: 32px;
    gap: 24px;

    button {
      border-color: var(--dropzone-action-color);
      margin: auto;
    }

    p {
      @include font(16px, 19px, 700);

      span {
        font-weight: 400;
      }
    }
  }
}
