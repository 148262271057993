@use '~scss/settings' as *;

.teacher-profile-build__profile-status {
  @include flex(column);

  height: 100%;

  .tpb-page-content {
    max-width: 700px;
    width: 100%;

    > .photo-preview {
      margin-bottom: 48px;
    }

    + button {
      max-width: 700px;
    }
  }
}
