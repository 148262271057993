@use '~scss/settings' as *;

.school-associations {
  > div:not(:first-of-type) {
    margin-top: 16px;
  }

  > div:last-of-type {
    @include flex(row);

    gap: 12px;
  }

  .multiple-select-field {
    .searchality-tag {
      color: $color-primary-light-teal;
      background-color: $color-primary-medium-teal;
      span {
        color: white;
      }
    }
  }
}
