@use '~scss/settings' as *;

@include phablet {
  .job-application-position-requirements {
    align-self: unset;

    &__reverse-toggle {
      > div {
        > * {
          margin: 0 !important;
        }

        gap: 16px;
      }
    }
  }
}
