@use '~scss/settings' as *;

.opportunity-feedback {
  @include card;
  @include flex(row, space-between, center);

  gap: 60px;

  &__text {
    @include flex(column);

    gap: 8px;
    flex: 1;

    &__title {
      @extend %paragraph-subtitle;
    }

    &__description {
      @extend %paragraph-descriptive;
    }
  }

  &__actions {
    @include flex(row, flex-end);

    flex: 1;
    gap: 8px;

    &__yes {
      svg {
        fill: $color-primary-dark-blue;
      }
    }
  }
}
