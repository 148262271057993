@use '~scss/color' as *;
@use '~scss/tools/tools' as *;

%card-container {
  padding: 16px;
  background: $color-white;
  border: 1px solid $color-primary-light-teal;
  border-radius: 10px;
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.04),
    0px 4px 4px rgba(235, 235, 235, 0.08),
    0px 4px 14px rgba(231, 231, 231, 0.05), 0px 4px 5px rgba(0, 0, 0, 0.05);
}
