@use '~scss/settings' as *;

.datepicker-field {
  .react-datepicker__input-container.react-datepicker-wrapper {
    width: unset;
  }
  .react-datepicker__custom-input-wrapper {
    @include flex(row, flex-start, center);
    @include base-input;
    background-color: $color-grey-fc;

    button {
      background-color: inherit;
    }

    input {
      @include font(16px, 18.75px, 400);

      flex: 1;
      color: $color-grey-2b;
      border: none;
      outline: none;
      padding: 18px 16px;
      border-radius: inherit;
      background-color: inherit;
      &:focus-within {
        border-color: $color-primary-dark-blue;
      }
    }
  }
}
