@use '~scss/settings' as *;

.two-options-select-field {
  &__error {
    @extend %input-descriptive;

    color: $color-error;
    margin-top: 8px;
    margin-left: 4px;
  }
}
