@use '~scss/settings' as *;

.job-application-not-logged-in {
  @include flex(row, space-between, center);

  border: 1px solid $color-primary-light-teal;
  border-radius: 20px;
  padding: 24px;
  gap: 16px;

  h3 {
    @extend %paragraph-subtitle;

    color: $color-secondary-light-grey;
    margin-bottom: 16px;
  }

  p {
    @extend %paragraph;
  }
}
