@use '~scss/settings' as *;

@include phablet {
  .share-vacancy-modal {
    .ncoded-modal__content {
      header {
        padding: 16px 16px 8px 20px;

        gap: 12px;

        button {
          flex-basis: 40px;
          margin-left: auto;
        }
      }

      main {
        padding: 12px 12px 16px 20px;

        .share-vacancy-modal {
          &__share-form {
            @include flex(column, stretch, stretch);

            gap: 12px;

            .multiple-select-field {
              .share-vacancy-modal__dropdown-item {
                > p {
                  flex: unset;
                }
              }
            }
          }

          &__via-link {
            align-self: stretch;
            > button {
              align-self: stretch;
            }
          }
        }
      }
    }
  }
}
