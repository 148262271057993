@use '~scss/settings' as *;

@include phablet() {
  .vacancy-profile-page {
    .heading-buttons-group {
      @include flex(column, flex-start, stretch);
      @include gap(unset);

      gap: 8px;

      button {
        max-height: 40px;
      }

      > button.ncoded-button {
        width: 100%;
      }

      > div {
        > button.ncoded-button {
          width: 100%;
        }
      }
    }

    .searchality-heading__right {
      align-self: stretch;
    }
  }
}
