@use '~scss/settings' as *;

.school-profile-review {
  .card-container {
    @extend %card-container;
    padding: 16px;
  }
  &__card {
    &__heading {
      margin-bottom: 16px;
      h5 {
        @extend %paragraph-subtitle-small;
      }
    }
    &__item {
      @include flex(column);

      label {
        @extend %input-descriptive;

        color: $color-grey-b5;
      }

      p {
        @extend %input;
        color: $color-black;
      }
    }
  }
}
