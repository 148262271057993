@use '~scss/settings' as *;

.education-certificates {
  &__cards {
    @extend %modal-card-layout;
  }

  button[class*='education-certificates__button'] {
    @extend %modal-card-button;
  }
}

.education-certificates-modal {
  .ncoded-modal__content {
    @extend %modal-card-content;
  }

  &__basic-info {
    @include flex(column);

    gap: 24px;
    flex: 1;

    &__first-row {
      @include flex(row, flex-start, stretch);
    }

    &__second-row {
      @include flex;
    }

    &__first-row,
    &__second-row {
      gap: 24px;
      flex: 1;

      > * {
        flex: 1;
      }
    }
  }

  &__add-dates {
    margin: 24px 0 12px;
  }

  &__date-range {
    @include flex;

    margin-top: 16px;
    gap: 24px;
  }

  &__checkbox {
    margin: 16px 0 24px;
  }
}
