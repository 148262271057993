@use '~scss/tools/tools' as *;
@use '../color' as *;

%modal-card-layout {
  display: grid;
  margin: 12px 0;
  gap: 24px;
}

%modal-card-button {
  padding: 12px 48px;

  gap: 16px;

  svg {
    @include size(14px);

    path {
      stroke: $color-primary-dark-blue;
    }
  }
}

%modal-card-content {
  width: 100%;
  max-width: 1000px;

  main {
    @include flex(column);

    gap: 24px;
  }

  footer {
    @include flex(row, flex-start);
  }
}
