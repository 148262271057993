@use '~scss/settings' as *;

.vacancy-fill-candidate-modal {
  .ncoded-modal {
    &__content {
      width: 800px;
      max-width: 90%;

      main {
        @include flex(column);
      }
    }
  }
}
