@use '~scss/settings' as *;

@include phablet() {
  .personal-information-edit-wrapper {
    @include flex(column);

    button[class*='text-button'] {
      margin-top: 15px;
    }
  }
}
