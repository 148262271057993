@use '~scss/settings' as *;

.upgrade-subscription {
  > p {
    @extend %paragraph-subtitle;

    margin-bottom: 20px;
  }

  &__links {
    @include flex(column);

    gap: 12px;

    svg {
      fill: $color-secondary-light-grey;

      > g {
        path {
          fill: $color-secondary-light-grey;
        }
      }
    }
  }
}
