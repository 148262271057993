@use '~scss/settings' as *;

.documents-requested-section {
  > p {
    @extend %heading4;

    margin-top: 24px;
  }

  @include flex(column);

  gap: 16px;
}
