@use '~scss/settings' as *;

.searchality-header {
  @include flex(row, space-between, center);

  position: relative;
  width: 100%;
  min-height: 90px;
  padding: 22px 48px;
  background-color: $color-white;

  &.shadow {
    z-index: 1;
    filter: drop-shadow(0px 8px 10px rgba(0, 0, 0, 0.04))
      drop-shadow(0px 4px 4px rgba(235, 235, 235, 0.08))
      drop-shadow(0px 4px 14px rgba(231, 231, 231, 0.05))
      drop-shadow(0px 4px 5px rgba(0, 0, 0, 0.05));
  }

  &__logo {
    @include size(154px, 46px);

    > img {
      @include size(100%);
    }
  }
}
