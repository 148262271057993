@use '~scss/settings' as *;

@include phablet {
  .toggle-field {
    &__group {
      @include flex(column);

      gap: 16px;

      &--reverse {
        @include gap(unset, row-reverse);
      }
    }
  }
}
