@use '~scss/settings' as *;

.auth {
  flex: 1;

  article {
    @include flex(row, center, center);
  }

  h1 {
    @extend %heading3;

    color: $color-primary-orange;
  }

  .information-section {
    max-width: 100%;
    width: 415px;
  }

  .auth-form {
    max-width: 509px;
    width: 100%;
  }
}
