@use '~scss/settings' as *;

.teacher-profile-preview {
  @include flex(column);
  margin: 0 auto;
  padding: 40px 0;
  &__actions {
    @include flex(row, space-between, center);
    margin-bottom: 64px;
    gap: 32px;

    button {
      @extend %paragraph-subtitle-small;
      height: 38px;
    }
    > div {
      @include gap(8px);
      @include flex(row, flex-end, center);
      > p {
        color: $color-primary-orange;
        @extend %paragraph-subtitle-small;
      }
    }

    p {
      @extend %paragraph-subtitle;

      color: $color-secondary-light-grey;
    }
  }
  .profile-not-ready-banner {
    margin-bottom: 48px;
  }
}
