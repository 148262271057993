@use '~scss/settings' as *;

.multiple-select-field {
  @include flex(column);

  gap: 16px;

  &__tags {
    @include flex(row, flex-start, center);

    flex-wrap: wrap;
    gap: 12px;
    max-width: 100%;
  }
  &__custom-option {
    .s-input-wrapper {
      flex: 1;
      margin-right: 32px;
    }

    @include flex(row, flex-start, center);
  }
}
