@use '~scss/settings' as *;

@include laptop {
  .auth-signup {
    max-width: 509px;
    margin: auto;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-template-areas:
      'a'
      'b'
      'c';
    row-gap: 32px;

    &__titles {
      h1 {
        @include font-size(32px, 37.5px);

        padding-right: 30%;

        + p {
          margin-top: 16px;
        }
      }
    }

    .auth-form {
      button.ncoded-button {
        width: 100%;
      }
    }
  }
}

@include phablet {
  .auth-signup {
    max-width: 509px;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-template-areas:
      'a'
      'b'
      'c';
    row-gap: 32px;

    &__titles {
      img {
        display: none;
      }

      h1 {
        @include font-size(32px, 37.5px);

        padding-right: 30%;

        + p {
          margin-top: 16px;
        }
      }

      @media (min-width: 520px) {
        h1 {
          color: $color-primary-orange !important;

          + p {
            color: $color-white !important;
          }
        }

        a {
          color: $color-white !important;
        }
      }

      @media (min-width: 570px) {
        h1 {
          color: $color-primary-orange !important;

          + p {
            color: $color-white !important;
          }
        }
      }
    }

    &__description {
      p {
        @include font-size(18px, 25px);

        margin-top: 28px;
        color: $color-primary-light-teal;
      }

      ul {
        color: $color-white;
      }
    }

    .auth-form {
      button.ncoded-button {
        width: 100%;
      }
    }

    &--teacher {
      .auth-signup__description {
        a,
        p {
          color: $color-primary-light-teal;
        }
      }
    }
  }
}
