@use '~scss/settings' as *;

.image-content-modal {
  .ncoded-modal {
    &__content {
      max-width: 420px;

      main {
        padding: 0 24px 32px;

        img {
          align-self: center;
        }

        p {
          @extend %paragraph-subtitle;
        }
      }
    }
  }
}
