@use '~scss/settings' as *;

.teacher-profile-builder-page-wrapper {
  padding-top: 36px;
  padding-bottom: 36px;
  padding-left: min(15%, 140px);
  padding-right: min(15%, 64px);
  width: 100%;
  display: flex;
  flex: 1 1 100%;
  overflow: auto;
  height: 100%;
  > form {
    flex: 1;
  }
}
