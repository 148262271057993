@use '~scss/settings' as *;

.side-navigation {
  @include flex(column, center, flex-start);
  @include gap(9px, column);

  padding: 0 90px;
  min-width: 320px;
  border-right: 1px solid $color-primary-light-teal;

  &__nav-link {
    @extend %paragraph;

    color: $color-grey-af;

    &::before {
      @include size(10px);

      left: 0;
      top: 50%;
      position: absolute;
      transform: translateX(-12px) translate(-100%, -50%);
      background-repeat: no-repeat;
      background-position: center;
      background-size: 100%;
    }

    &.completed {
      color: $color-primary-dark-blue;
      position: relative;

      &::before {
        content: '';
        background-image: url('/assets/images/Done.icon.svg');
      }
    }

    &.active {
      color: $color-grey-2b;
      position: relative;

      &::before {
        content: '';

        background-image: url('/icons/Arrow.icon.svg');
      }
    }

    &.disabled {
      pointer-events: none;
    }
  }
}
