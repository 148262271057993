@use '~scss/settings' as *;

.job-application-header {
  width: 100%;
  background-color: $color-white;
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.04),
    0px 4px 4px rgba(235, 235, 235, 0.08),
    0px 4px 14px rgba(231, 231, 231, 0.05), 0px 4px 5px rgba(0, 0, 0, 0.05);
  padding: 48px 20px 48px 0;

  &__content {
    max-width: 900px;
    margin: 0 auto;
    @include flex(row, space-between);

    &__left {
      @include flex(column);
      @include gap(8px, column);

      flex: 1;
      width: 100%;

      &__title {
        @include font(32px, 38px, 800);

        @extend %opensans;

        overflow-wrap: break-word;
      }

      &__school-container {
        @include flex(row, flex-start, flex-start);
        @include gap(22px);

        &__name {
          @extend %paragraph;

          color: $color-primary-teal;

          overflow-wrap: break-word;
          max-width: 75%;
        }

        &__type {
          @extend %paragraph;

          color: $color-secondary-light-grey;
          position: relative;
          max-width: 100%;

          &::before {
            @include size(6px);

            content: '';
            background-color: currentColor;
            border-radius: 50%;
            position: absolute;
            left: -14px;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }
    }
  }
  img {
    width: 100%;
    max-width: 160px;
    max-height: 72px;
    object-fit: contain;
  }
}
