@use '~scss/settings' as *;

@include phablet() {
  .tpb-contact-information {
    &__row-fields {
      @include flex(column);
      @include gap(unset);

      gap: 16px;
    }
  }
}
