@use '~scss/settings' as *;

.vacancy-candidates-mathing-not-paid {
  @include flex(row, center, flex-start);

  gap: 60px;

  margin-top: 64px;

  img {
    width: 170px;
  }

  &__right {
    max-width: 400px;

    &__title {
      @extend %paragraph-subtitle;
    }

    &__subtitle {
      @extend %paragraph-descriptive;

      margin-top: 8px;
    }

    &__actions-container {
      @include flex(row, flex-start, flex-start);

      gap: 12px;
      margin-top: 12px;

      a {
        @include flex(row, center, center);

        border: 2px solid $color-primary-dark-blue;
        color: $color-primary-dark-blue;
        padding: 10px;
        font-size: 16px;
        min-width: unset;
        width: max-content;
        border-radius: $border-radius;
        font-weight: 400;
        height: 51px;
      }
    }
  }
}
