@use '~scss/general/general' as *;

.searchality-applications {
  @include page-content-container();
  width: 100%;
  max-width: 900px;

  .applications {
    details {
      display: none;
    }
  }
}
