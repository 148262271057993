@use '~scss/settings' as *;

.negative-feedback-modal {
  .ncoded-modal {
    &__content {
      max-width: 540px;

      header {
        display: none;
      }

      main {
        padding: 20px;

        > p:first-of-type {
          @extend %paragraph-subtitle;

          margin-bottom: 8px;
        }

        form {
          @include flex(column, flex-start, flex-start);

          gap: 12px;
          width: 100%;
          margin-top: 16px;

          .ncoded-checkbox {
            width: 100%;
            flex-direction: row-reverse;
            justify-content: space-between;
            gap: 30px;
            padding: 16px 20px 16px 12px;
            border: 1px solid $color-primary-light-teal;
            border-radius: $border-radius-6;
            background-color: $color-white;

            &--checked {
              background-color: $color-primary-dark-blue;

              .ncoded-checkbox__label {
                color: $color-white;
              }
            }
          }
        }
      }
    }
  }
}
