@use '~scss/settings' as *;

.tags {
  @include flex(column);
  &__add-option {
    flex: 1;
    @include flex(row, space-between, center);
    @include gap(16px);
    .s-input-wrapper {
      flex: 1;
    }
  }
  &__tags {
    margin-top: 16px;

    @include flex(row, flex-start);
    .searchality-tag {
      margin-right: 16px;
      margin-bottom: 16px;
    }
    flex-wrap: wrap;
  }
}
