@use '~scss/settings' as *;

.vacancy-wizard-page {
  @include flex(row, space-around);

  &__title-container {
    > div {
      @include flex(row, flex-start, center);
      @include gap(24px);
      min-height: 50px;

      h1 {
        @extend %paragraph-subtitle;
        position: relative;

        &::before {
          position: absolute;
          content: '';
          width: 4px;
          height: 4px;
          background-color: $color-primary-teal;
          border-radius: 50%;
          left: -12px;
          top: 50%;
          transform: translate(-50%, -50%);
        }
      }
      span {
        @include font(20px, 27px, 400);

        color: $color-primary-teal;
      }
    }
    > p {
      @extend %paragraph-descriptive;
    }
  }
  button[class*='text-button'] {
    @extend %paragraph;

    background-color: transparent;
    min-height: fit-content;
    color: $color-primary-dark-blue;
    padding: 0;

    svg {
      width: 16px;
    }
  }

  button[class*='color-teal'] {
    color: $color-primary-teal;
  }

  .buttons-container {
    button:first-of-type {
      margin-right: 12px;
    }
  }

  form {
    @include flex(column);
    @include gap(28px, column);
    width: 500px;
    flex: unset;
  }
}
