@use '~scss/settings' as *;

.request-video {
  @include flex(column);

  gap: 12px;
  margin-top: 28px;

  &__toggle-container {
    @include flex(row, space-between, center);

    gap: 46px;

    &__text {
      @include flex(column);

      gap: 8px;

      &__title {
        @extend %heading4;
      }

      &__desc {
        @extend %paragraph-descriptive;
      }
    }
  }

  .transparent {
    background-color: transparent;
  }

  &__video-wrapper {
    padding: 12px;
    border: 1px solid $color-primary-light-teal;
    border-radius: $border-radius-6;
    background-color: $color-white;

    &__desc {
      @extend %input-descriptive;

      margin-bottom: 8px;
    }

    &__link {
      @extend %paragraph-subtitle-small;

      text-decoration: none;
      color: $color-black;
      width: 100%;
      display: block;
      overflow-wrap: break-word;
    }
  }
}
