@use '~scss/settings' as *;

@include phablet {
  .vacancy-preview-card {
    @include flex(column, space-between, center);

    gap: 16px;
    padding: 35px 15px;
  }
}
