@use '~scss/settings' as *;

.searchality-pagination {
  @include flex(row, center, center);

  gap: 24px;
  margin-top: 40px;

  .ncoded-button--solid {
    background-color: $color-grey-eb;
    color: $color-secondary-light-grey;
  }

  &__pages {
    @include flex;

    gap: 16px;

    .page-button {
      @extend %opensans;
      @extend %paragraph-subtitle;

      &--active {
        background-color: $color-primary-light-teal;
      }
    }

    span {
      font-size: 30px;
      align-self: flex-end;
      color: $color-primary-teal;
    }
  }

  button[class*='arrow'] {
    padding: 0;

    &:disabled {
      background-color: $color-grey-eb;
      opacity: 0.7;

      svg {
        path {
          stroke: $color-text-light-grey;
        }
      }
    }
  }

  .arrow-right {
    svg {
      transform: rotate(180deg);
    }
  }

  @media (max-width: 440px) {
    .arrow-right,
    .arrow-left {
      display: none;
    }
  }
}
