@use '~scss/settings' as *;

.job-application-login {
  background: radial-gradient(
    112.74% 222.95% at 101.53% 6.84%,
    $color-primary-dark-blue 0%,
    #007078 49.75%,
    #bdd8d9 100%
  );

  @include flex(row, center, center);
  height: 100%;
  padding: 24px;

  form {
    max-width: 450px;
    background-color: $color-white;
    padding: 24px;
    border-radius: 20px;

    a {
      @include flex(row, center, center);
    }

    img {
      @include size(154px, 46px);
    }
    > button {
      width: 100%;
    }
    @include gap(16px, column);
  }
}
