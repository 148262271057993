@use '~scss/settings' as *;

@include phablet() {
  .member-information {
    @include flex(column, flex-start, stretch);

    gap: 32px;

    &__left {
      align-items: center;
    }
  }
}
