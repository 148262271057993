@use '~scss/settings' as *;

.vacancy-settings {
  &__description {
    @extend %input-descriptive;

    margin: 10px 0 40px;
    color: $color-text-light-grey;
  }
  .s-select-wrapper {
    max-width: 300px;
    margin-bottom: 20px;
  }
}
