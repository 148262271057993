@use '~scss/settings' as *;

.application-upload-documents-teacher-wrapper {
  @include flex(row, space-around, flex-start);

  position: relative;

  .go-back-button {
    position: absolute;
    left: 0;
    top: -15px;
  }
}
