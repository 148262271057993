@use '~scss/settings' as *;

.job-application-section {
  @include flex(column);
  @include gap(32px, column);

  &__header {
    @include flex(row, space-between, flex-end);

    position: relative;
    padding-bottom: 8px;

    &--with-margin {
      margin-top: 32px;
    }
    &--bordered {
      border-bottom: 1px solid $color-primary-light-teal;
    }

    &__title-container {
      @include flex(column);
      @include gap(8px, column);

      max-width: 500px;

      &__title {
        @include flex(row, flex-start, center);

        @extend %heading4;
        @extend %opensans;

        gap: 4px;
      }

      &__description {
        @extend %paragraph-descriptive;

        white-space: pre-wrap;
      }
    }

    button.clear-form-button {
      @include flex(row, flex-end, flex-end);

      @extend %paragraph;

      background-color: transparent;
      color: $color-secondary-light-grey-2;
      padding: 0;
      min-width: 120px;
    }
  }
}
