@use '~scss/settings' as *;

.job-application-hcr {
  @include flex(column);

  max-width: 550px;
  width: 100%;
  gap: 32px;
  align-self: center;

  &__passports {
    &__title {
      font-weight: 700;
      margin-bottom: 4px;
    }

    &__description {
      @extend %paragraph-descriptive;
      margin-bottom: 16px;
    }
  }

  &__work-permit {
    &__title {
      @extend %heading4;
    }

    &__description {
      @extend %paragraph-descriptive;

      margin-top: 8px;
    }
  }

  .toggle-field {
    margin-top: 37px;

    &__group {
      @include flex(column-reverse);
    }
  }
}
