@use '~scss/settings' as *;

.proceed-applicant-modal {
  main {
    @include flex(column);

    gap: 24px;

    form {
      @include flex(column);

      width: 100%;
      gap: 12px;

      .ncoded-checkbox {
        margin-top: 12px;
        width: 90%;

        .ncoded-checkbox__box {
          align-self: unset;
        }
      }

      .ncoded-textarea-container {
        &__wrapper {
          min-height: 360px;
        }
      }

      .actions {
        @include flex(row, flex-end);

        gap: 8px;
        margin-top: 12px;
      }
    }
  }
}
