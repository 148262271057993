@use '~scss/settings' as *;

.candidates-empty-state {
  @include flex(column, center, center);

  img {
    width: 100%;
    max-width: 600px;
    margin-top: 48px;
  }

  &__title {
    @extend %heading4;
    @extend %opensans;

    color: $color-secondary-light-grey;
    margin-bottom: 8px;
    text-align: center;
  }

  &__subtitle {
    @extend %paragraph;

    color: $color-primary-dark-blue;
    text-align: center;
  }
}
