@use '~scss/settings' as *;

.edit-upcoming-invoice-details {
  @include flex(column);

  flex: 1;
  background-color: $color-white;
  padding: 40px 180px 60px;
  border-radius: $border-radius-10;

  h1 {
    @extend %paragraph-subtitle;
  }

  p.coupon-description {
    color: $color-primary-teal;
    margin-top: 8px;
  }

  p.subscription-description {
    @extend %paragraph-subtitle-small;

    background-color: $color-gray-fa;
    padding: 22px 14px;
    border-radius: $border-radius-10;
    margin-top: 32px;
  }

  .po-number,
  .coupon {
    @include flex(row, flex-start, center);

    gap: 16px;

    > div {
      flex: 1;
    }

    button {
      flex: 0 0 150px;
    }
  }

  .payment-methods,
  .po-number,
  .coupon {
    margin-top: 32px;
  }
}

@include phablet() {
  .edit-upcoming-invoice-details {
    padding: 28px;

    .po-number,
    .coupon {
      @include flex(column);

      button {
        flex: 0;
        min-height: 51px;
      }
    }
  }
}

@include laptop() {
  .edit-upcoming-invoice-details {
    padding: 48px;
  }
}
