@use '~scss/settings' as *;

@include phablet() {
  .searchality-profile-main {
    --pSize: 136px;
  }
}

@media (max-width: 570px) {
  .searchality-profile-main {
    &__contact {
      flex-direction: column;
      align-items: flex-end;
      gap: 12px;
      margin-bottom: 20px;
    }
  }
}
