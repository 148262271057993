@use '~scss/settings' as *;

.candidate-uploaded-documents {
  @include flex(column);

  gap: 12px;

  &__title {
    @extend %heading4;

    color: $color-primary-dark-blue;
  }

  &__links {
    @include flex(column);

    gap: 16px;
  }
}
