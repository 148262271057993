@use '~scss/settings' as *;

.teacher-application-upload-requested-documents {
  border-radius: $border-radius-10;
  border: 1px solid $color-primary-light-teal;
  background: $color-white;
  padding: 24px 20px;
  box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.05),
    0px 4px 14px 0px rgba(231, 231, 231, 0.05),
    0px 4px 4px 0px rgba(235, 235, 235, 0.08),
    0px 8px 10px 0px rgba(0, 0, 0, 0.04);

  &__ul-paragraph {
    @extend %heading4;

    color: $color-primary-dark-blue;

    margin-top: 20px;
  }

  &__title {
    @include flex(row, flex-start, center);

    color: $color-secondary-light-grey;
    margin-bottom: 12px;
    gap: 10px;

    > p {
      @extend %paragraph-subtitle;

      color: $color-black;
    }
  }

  ol {
    li {
      gap: 12px;
      padding-left: 4px;
      margin-left: 10px;
      margin-top: 4px;

      > p {
        @include flex(row, flex-start, center);

        gap: 12px;
      }
    }
  }

  &__date {
    @extend %paragraph-subtitle-small;

    color: $color-primary-orange;
    margin-bottom: 20px;

    &--green {
      color: $color-primary-teal;
    }
  }

  .searchality-basic-tag {
    width: fit-content;
  }
}
