@use '~scss/settings' as *;

.searchality-profile-header {
  @include flex(column);

  gap: 48px;
  margin-bottom: 32px;

  &__main {
    @include flex(row, space-between, center);

    gap: 32px;

    p {
      @extend %paragraph-subtitle;

      color: $color-secondary-light-grey;
    }

    &__actions {
      @include flex(row, flex-end, center);

      gap: 16px;

      .ncoded-button > svg {
        @include size(14px);

        rotate: 180deg;
      }
      p {
        color: $color-primary-orange;
        @extend %paragraph-subtitle-small;
      }
    }
  }
}
