@use '~scss/settings' as *;

.school-profile {
  h1 {
    @extend %paragraph-subtitle;

    margin: 12px 0 42px;
    max-width: 100%;
    overflow-wrap: break-word;
  }

  .widget-url-input {
    margin-top: 32px;
  }

  &__description {
    @extend %paragraph-subtitle-small;

    margin-bottom: 24px;
  }

  .linked-association-empty-state {
    &__title {
      @extend %paragraph-subtitle-small;

      color: $color-text-dark-grey;
    }
  }

  &__remove-button.ncoded-button.ncoded-button--primary {
    margin: 24px 0 56px;
    min-width: 240px;
  }

  &__members {
    width: 100%;
    margin-top: 48px;

    &__title {
      @extend %paragraph-subtitle-small;
      @extend %opensans;

      color: $color-text-dark-grey;
    }

    &__sub {
      color: $color-text-dark-grey;
      margin-top: 8px;
    }

    &__table {
      margin-top: 21px;
      width: 100%;
    }
  }
}
