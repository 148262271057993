@use '~scss/settings' as *;

.order-summary {
  width: 320px;
  background-color: $color-gray-fa;
  padding: 22px 14px;
  border-radius: $border-radius-10;
  height: 100%;

  &__title {
    @extend %heading4;

    @extend %opensans;
  }

  &__subtotal {
    @include flex(row, space-between);

    @extend %paragraph-descriptive;

    margin-top: 8px;
  }

  &__description {
    @extend %paragraph-descriptive;

    margin-top: 8px;
  }

  &__coupon {
    @extend %paragraph-subtitle-small;

    margin-top: 4px;
    color: $color-primary-teal;
  }

  &__cart {
    @include flex(column);

    gap: 24px;
    margin-top: 32px;
  }

  &__total-price {
    @include flex(row, space-between);

    @extend %paragraph-subtitle-small;

    margin-top: 16px;

    > span {
      color: $color-primary-teal;
    }
  }

  &__total-price-description {
    @extend %paragraph-descriptive;

    margin-top: 16px;
  }

  > button.ncoded-button {
    width: 100%;
    margin-top: 14px;
  }
}
