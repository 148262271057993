@use '~scss/settings' as *;

.hcr-form-fields {
  @include flex(column);

  gap: 24px;

  .disabled-label {
    color: $color-secondary-grey;
  }

  .description {
    @extend %paragraph-descriptive;

    color: $color-secondary-light-grey;
    margin: 8px 0px 12px;
  }

  &__min-max {
    .form-field-label {
      margin-bottom: 12px;
    }

    > div {
      @include flex(row);

      gap: 12px;

      > * {
        flex: 1;
      }
    }
  }

  &__passport {
    h3 {
      @extend %paragraph-subtitle;

      margin-top: 20px;
    }

    &__preferred-label {
      margin: 20px 0 12px;
    }

    &__reject-label {
      margin-top: 24px;
    }
  }
}
