@use '~scss/settings' as *;

.school-information-preview {
  @include flex(column);

  gap: 16px;

  .slider-buttons {
    align-self: stretch;
    justify-content: space-around;

    svg {
      @include size(18px);
    }
  }
}
