@use '~scss/settings' as *;

@media (max-width: 1000px) {
  .select-school-modal {
    .ncoded-modal {
      &__content {
        min-width: unset;

        input {
          display: none;
        }

        .school-card {
          @include flex(column, flex-start, stretch);
        }
      }
    }
  }
}
