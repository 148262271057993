@use '~scss/settings' as *;

.job-application-logged-in {
  @include flex(row, space-between);

  &__user {
    @include flex(row, flex-start, center);
    .photo-preview {
      margin-right: 24px;
    }
    &__info {
      h5 {
        @extend %paragraph;
        color: $color-black;
      }
      p {
        @extend %input-descriptive;
        color: $color-text-light-grey;
      }
    }
  }
  &__actions {
    @include flex(row);
    @include gap(8px);
  }
}
