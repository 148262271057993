@use '~scss/settings' as *;

.image-section {
  @include flex(row, flex-start, center);

  gap: 30px;

  .photo-preview {
    border: 3px solid $color-white;
  }

  span {
    @include size(80px);
    border-radius: 50%;
    border: 3px solid $color-white;
  }

  span {
    @include flex(row, center, center);
    @include font-size(20px, 27px);
  }

  &__information {
    > p {
      @include font(32px, 38px, 800);

      color: $color-primary-dark-blue;
      margin-bottom: 16px;
    }

    &__permission {
      @include flex;

      gap: 14px;

      p {
        @extend %paragraph;

        color: $color-secondary-light-grey-2;
      }

      p[class*='image-section__information__permission__role'] {
        color: $color-primary-dark-blue;
        position: relative;

        &::before {
          @include size(4px);

          position: absolute;
          content: '';
          top: 50%;
          left: -7px;
          transform: translate(-50%, -50%);
          background-color: currentColor;
          border-radius: 50%;
        }
      }
    }
  }
}
