@use '~scss/settings' as *;

.vacancies-fill-factory {
  &__description {
    @extend %input-descriptive;
    margin-bottom: 32px;
    margin-top: 12px;
    color: $color-secondary-light-grey;
  }

  &__pagination-info-wrapper {
    @include flex(row, space-between, center);

    margin-bottom: 8px;
  }
}
