@use '~scss/settings' as *;

@include phablet() {
  .searchality-tabs-navigation {
    width: 90%;
    overflow-x: auto;

    margin-bottom: 20px;

    &::-webkit-scrollbar {
      display: none;
    }

    &__link {
      white-space: nowrap;
    }
  }
}
