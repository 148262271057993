@use '~scss/settings' as *;

.role-step {
  @include flex(column);

  width: 100%;
  gap: 12px;

  p.field {
    @extend %paragraph-subtitle-small;
  }

  .tags {
    @include flex;

    flex-wrap: wrap;
    gap: 8px;

    .searchality-tag {
      padding: 8px;
      width: fit-content;
      max-width: 100%;

      span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      button {
        margin-left: 8px;
      }
    }
  }

  .emails-error {
    @extend %input-descriptive;

    color: $color-error;
    padding: 4px;
  }
  &__email-wrapper {
    @include flex(row, flex-start, center);
    .s-input-wrapper {
      flex: 1;
      margin-right: 16px;
    }
  }
}
