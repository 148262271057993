@use '~scss/settings' as *;

.application-preview {
  @include flex(column);

  gap: 24px;

  .teacher-application-upload-requested-documents {
    margin-bottom: 24px;
  }

  h4 {
    @extend %paragraph-subtitle;

    margin-bottom: 20px;
  }

  .title-margin-top {
    margin-top: 16px;
  }
}
