@use '~scss/tools/tools' as *;
@use '~scss/_breakpoints' as *;

@mixin page-content-container() {
  @include size(100%);
  @include flex(column);

  max-width: 900px;
  margin: 0 auto;

  @include tablet() {
    max-width: 100%;
  }

  @media screen and (min-width: 1440px) {
    max-width: 940px;
  }

  @media screen and (min-width: 1728px) {
    max-width: 1120px;
  }

  @media screen and (min-width: 1920px) {
    max-width: 60%;
  }
}
