@use '~scss/settings' as *;

.vacancy-profile-page {
  margin: 0 auto;
  width: 100%;
  .heading-buttons-group {
    @include flex(row, center, center);
    @include gap(8px);
    button {
      max-height: 40px;
    }
    > .ncoded-button {
      @extend %paragraph-subtitle-small;
      &--outline {
        color: $color-primary-dark-blue;
      }
    }
  }
  .searchality-heading {
    h1 {
      font-size: 20px;
    }

    .button-dropdown {
      > button.ncoded-button {
        padding: 10px 16px;
      }
    }
  }
  &__vacancy-status {
    @extend %paragraph-descriptive;
    color: $color-text-light-grey;
    > span {
      color: $color-primary-teal;
      @extend %paragraph-subtitle-small;
    }
  }
  .vacancy-candidates-info {
    margin: 32px 0;

    @include flex(row, space-between);
    flex: 1;
    .ncoded-select {
      width: 200px;
    }
    h6 {
      margin-bottom: 8px;
      @extend %paragraph-subtitle-small;
      color: $color-text-dark-grey;
    }
    .searchality-pagination-info {
      @extend %input-descriptive;

      color: $color-text-light-grey;
    }
  }

  &--edit {
    max-width: 900px;
  }
}
